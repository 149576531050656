import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const SectionFour = props => {
  const {
    EFAP, intl, removeLegalFinancial, EAP, path,
  } = props;
  return (
    <div className={EFAP ? 'section-4' : 'EFAP-hidden'}>
      <div className="your-efap-services">
        {
          EAP ?
          intl.formatMessage({
            id: 'eap.yourEap',
            defaultMessage: 'Your EAP Services',
          }) :
          intl.formatMessage({
            id: 'eap.yourEfap',
            defaultMessage: 'Your EAP Services',
          })
        }
        {}
      </div>
      <ul>
        <li>
          {intl.formatMessage({
            id: 'eap.mentalHealth',
            defaultMessage:
              'Mental health prevention, management and treatment',
          })}
        </li>
        <li>
          {intl.formatMessage({
            id: 'eap.stressManagement',
            defaultMessage: 'Stress management and reduction',
          })}
        </li>
        <li>
          {intl.formatMessage({
            id: 'eap.relationshipFamily',
            defaultMessage: 'Relationship and family support',
          })}
        </li>
        {
          !removeLegalFinancial &&
          <li>
            {intl.formatMessage({
              id: 'eap.legalFinancial',
              defaultMessage: 'Legal and financial consultation',
            })}
          </li>
        }
        <li>
          {path === 'atb' ? 'Coaching services' :
            intl.formatMessage({
              id: 'eap.researchAdvisory',
              defaultMessage: 'Research/advisory services',
            })}
        </li>
      </ul>
    </div>
  );
};

SectionFour.propTypes = {
  EFAP: PropTypes.bool,
  intl: PropTypes.object,
  removeLegalFinancial: PropTypes.bool,
  EAP: PropTypes.bool,
  path: PropTypes.string,
};

SectionFour.defaultProps = {
  intl: {},
  EFAP: false,
  EAP: false,
  removeLegalFinancial: false,
  path: null,
};

export default injectIntl(SectionFour);
