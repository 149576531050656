import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import SectionContainer from '../../components/SectionContainer';
import { securityData } from '../../utils/pageUtils';
import { COUNTRY } from '../../utils/environment';
import Check from '../../images/_check.svg';
import computerSession from '../../images/custom/illustrations/computersession.svg';

export const usSecurityData = [
  {
    id: 'eap.usOnlineServices',
    defaultMessage:
      'We follow strict US regulations for online health services.',
  },
  {
    id: 'eap.usEncryption',
    defaultMessage:
      'All information is fully encrypted on a regularly audited server in the US.',
  },
  {
    id: 'eap.onlyYou',
    defaultMessage:
      'Only you and your counsellor have access to your mental health information.',
  },
  {
    id: 'eap.usCounsellors',
    defaultMessage:
      'All counsellors reside in the US, are highly experienced, and are regulated professionals.',
  },
];

const SectionFive = (props) => {
  const secData = COUNTRY === 'US' ? usSecurityData : securityData;
  return (
    <SectionContainer container num={5}>
      <div className="section-5-container">
        <div>
          <h2>{props.intl.formatMessage({ id: 'eap.yourSecurity', defaultMessage: 'Your security is our top priority:' })}</h2>
          <div style={{ marginTop: '50px' }} className="security-box">
            {_.map(secData, (data, i) => (
              <p key={i} style={{ display: 'flex', alignItems: 'center' }}>
                <img style={{ marginRight: '34px' }} src={Check} alt="check" />
                {props.intl.formatMessage({ id: data.id, defaultMessage: data.defaultMessage })}
              </p>
          ))}
          </div>
        </div>
        <img src={computerSession} alt="" />
      </div>
    </SectionContainer>
  );
};

SectionFive.propTypes = {
  intl: PropTypes.object,
};

SectionFive.defaultProps = {
  intl: {},
};

export default injectIntl(SectionFive);
