import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import MetaTags from 'react-meta-tags';

import {
  SERVER_URL,
  THERAPY_URL,
  OTHER_SERVER_URL,
  OTHER_THERAPY_URL,
  COUNTRY,
} from '../../utils/environment';
import {
  unauthAuditProgress,
  auditProgress,
  parseEapCodeFromPath,
} from '../../utils/pageUtils';
import LogEventOnMount from '../LogEventOnMount';
import { OPEN_SIGNUP, logEvent, COMPLETED_SIGNUP } from '../../utils/amplitude';

class MLFSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailError: false,
    };
  }
  componentDidMount() {
    unauthAuditProgress(parseEapCodeFromPath(), 'open_signup');
  }

  handleChange = event => {
    const { name, value } = event.target;
    if (name === 'email') {
      this.emailExists(value);
    }
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.signUp();
  };

  emailExists = email => {
    axios
      .get(`${SERVER_URL}/api/signup/check_email?email=${email}`)
      .then(() => {
        this.setState({ emailError: false });
      })
      .catch(() => {
        this.setState({
          emailError: true,
        });
      });
  };

  signUp = () => {
    const { emailError, country, status } = this.state;
    if (emailError) {
      return false;
    }
    const corporate_account = window.location.search.split('eap=')[1];

    let apiUrl;
    let appUrl;

    if (country === 'Canada' && COUNTRY === 'CA') {
      apiUrl = SERVER_URL;
      appUrl = THERAPY_URL;
    } else {
      apiUrl = OTHER_SERVER_URL;
      appUrl = OTHER_THERAPY_URL;
    }
    if (status === '1') {
      axios
        .post(`${SERVER_URL}/api/signup`, {
          ...this.state,
          corporate_account,
        })
        .then(response => {
          const token = response.data.access_token;
          const inkblotUrl = `${THERAPY_URL}/redirect/${token}`;
          logEvent(COMPLETED_SIGNUP, { page: parseEapCodeFromPath() });
          auditProgress(parseEapCodeFromPath(), 'completed_signup', token);
          window.location.replace(inkblotUrl);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
    } else {
      const {
        email, first_name, last_name, password,
      } = this.state;
      axios
        .post(`${apiUrl}/api/v2/eap_dependants`, {
          corporate_account,
          country,
          email,
          first_name,
          last_name,
          password,
          relationship: status === '3' ? 'spouse' : 'dependant',
          source: 'therapy',
        })
        .then(response => {
          const token = response.data.access_token;
          const inkblotUrl = `${appUrl}/redirect/${token}`;
          window.location.replace(inkblotUrl);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
    }
    return true;
  };

  render() {
    const showFullWidth = ['mapleleaffoods'].includes(window.location.search.split('eap=')[1]);
    return (
      <div className="custom-auth custom-dependants">
        <MetaTags>
          <meta name="robots" content="noindex" />
        </MetaTags>
        <div
          className="dependants-container custom-auth-container"
          style={{ margin: '50px auto' }}
        >
          <div className="dependant-auth-form">
            <h2>
              {this.props.intl.formatMessage({
                id: 'eap.signup',
                defaultMessage: 'Sign up',
              })}
            </h2>
            <form onSubmit={this.handleSubmit}>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.firstName',
                    defaultMessage: 'First Name',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="first_name"
                  placeholder="John"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.lastName',
                    defaultMessage: 'Last Name',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="last_name"
                  placeholder="Doe"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.email',
                    defaultMessage: 'Email',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="email"
                  placeholder="jdoe@example.com"
                  className={showFullWidth && 'full-width'}
                />
                {this.state.emailError && (
                  <p className="error-text">
                    Email Already Exists. Please Sign In Below.
                  </p>
                )}
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.role',
                    defaultMessage: 'Status',
                  })}
                </h4>
                <select
                  className="role-dropdown"
                  onChange={value => this.handleChange(value)}
                  type="text"
                  name="status"
                  defaultValue=""
                  required
                >
                  <option value="">Select an option</option>
                  <option value="1">Employee/Member</option>
                  <option value="3">Spouse</option>
                  <option value="4">Dependent</option>
                </select>
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.country',
                    defaultMessage: 'Country',
                  })}
                </h4>
                <select
                  className="role-dropdown"
                  onChange={value => this.handleChange(value)}
                  type="text"
                  name="country"
                  defaultValue=""
                  required
                >
                  <option value="">Select an option</option>
                  <option value="Canada">Canada</option>
                  <option value="United States">United States</option>
                </select>
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.password',
                    defaultMessage: 'Password',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="password"
                  name="password"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.confirmPassword',
                    defaultMessage: 'Retype Password',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="password"
                  name="password"
                />
              </div>
              <div>
                <button type="submit">
                  {this.props.intl.formatMessage({
                    id: 'eap.signup',
                    defaultMessage: 'Sign Up',
                  })}
                </button>
              </div>
            </form>
            <h4>
              {this.props.intl.formatMessage({
                id: 'eap.alreadyHave',
                defaultMessage: 'Already have an account?',
              })}
              <Link to="/sign_in?eap=mapleleaffoods" style={{ color: '#2a5ccd' }}>
                {this.props.intl.formatMessage({
                  id: 'eap.signin',
                  defaultMessage: 'Sign In',
                })}
              </Link>
            </h4>
          </div>
        </div>
        <LogEventOnMount
          eventType={OPEN_SIGNUP}
          eventProperties={{ page: parseEapCodeFromPath() }}
        />
      </div>
    );
  }
}

MLFSignup.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(MLFSignup);
