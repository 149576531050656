/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import inkblotLogoDark from '../../images/custom/logos/inkblot-logo-dark-blue.svg';

const SectionTwo = props => {
  const {
    logo,
    secondLogo,
    title,
    EFAP,
    minutes,
    intl,
    unlimitedSessions,
    unlimitedSessionsFr,
    subsequentSessions,
    subsequentSessionsFr,
    annuallyCovered,
    frLogo,
    frTitle,
    removeSecondParagraph,
    finalParagraph,
    heures,
    efapSecond,
    efapSecondFr,
    efapThird,
    finalParagraphFr,
  } = props;
  return (
    <div className="section-2">
      <div className="section-2-wrapper">
        <div className="logo-container">
          <div className="logo-block custom-logo two-custom-logos">
            <img src={intl.locale === 'fr' && frLogo ? frLogo : logo} alt={`${title} logo`} id="customLogo" />
            {secondLogo && <img src={secondLogo} alt={`${title} second logo`} id="customLogo" style={{ maxWidth: title === 'SCI Ltd' ? '40px' : '' }} />}
          </div>
          <div className="logo-block">
            <img src={inkblotLogoDark} alt="inkblot logo" id="customLogo" />
          </div>
        </div>
        {/* unlimitedSessions is true and this is the only paragraph in view */}
        <div
          className={
            unlimitedSessions ? 'custom-inkblot-collab' : 'EFAP-hidden'
          }
        >
          <p style={{ fontSize: '22px' }}>
            {intl.locale === 'fr' ? unlimitedSessionsFr : unlimitedSessions}
          </p>
        </div>
        {/* First paragraph for non EFAP companies */}
        <div
          className={
            !EFAP && !unlimitedSessions
              ? 'custom-inkblot-collab'
              : 'EFAP-hidden'
          }
        >
          {intl.locale === 'fr' ?
            (
              <p>
                <span>Votre santé mentale est importante</span>,
                c’est pourquoi {frTitle || title} s’est associée à Inkblot, afin de vous offrir
                des vidéoconsultations et du soutien en matière de santé mentale,
                en toute confidentialité et convivialité.
              </p>
            ) :
            (
              <p>
                <span>Your mental health matters</span> and that’s why {title} and
                Inkblot have partnered to provide you with confidential and
                convenient video counselling and mental health support.
              </p>
            )
          }
          {/* Second paragraph for non EFAP companies */}
          {!removeSecondParagraph ?
            title === 'Canadian Stage' ?
              <p>
                Your initial 30-minute individual session is complimentary, and
                through Canadian Stage, 60 minutes of counselling per month
                (no rollover) are covered.
              </p> :
              <p>
                {intl.locale === 'fr' ? 'Votre première séance individuelle est gratuite' : 'Your initial individual session is complimentary'}
                {minutes !== null ?
                intl.locale === 'fr'
                ? ` et par le biais de ${title}, vos ${heures} de counseling${annuallyCovered ? ' par année' : ''} sont couvertes.`
                : `, and through ${title}, your next ${minutes} of counselling${annuallyCovered ? ' per year' : ''} are covered.`
                : '.'
              }
              </p>
              :
              null
          }
          {/* Third paragraph for non EFAP companies */}
          <p>
            {intl.locale === 'fr' && subsequentSessionsFr ? subsequentSessionsFr : subsequentSessions}
          </p>
          {
            finalParagraph &&
            <p>
              {intl.locale === 'fr' && finalParagraphFr ? finalParagraphFr : finalParagraph}
            </p>
          }
        </div>
        {/* Two paragraphs for EFAP companies */}
        <div className={EFAP ? 'custom-efap-collab' : 'EFAP-hidden'}>
          <p>
            {title}{' '}
            {intl.formatMessage({
              id: 'eap.partnered',
              defaultMessage:
                'has partnered with Inkblot to provide you with easy to access support for your life, work and mental health challenges - any time, any place.',
            })}
          </p>
          {
            efapSecond && <p>{intl.locale === 'fr' ? efapSecondFr : efapSecond}</p>
          }
          <p style={{ fontSize: '1.125rem', fontWeight: 600 }}>
            {intl.formatMessage({
              id: 'eap.servicesAvailable',
              defaultMessage:
                'Inkblot’s services are voluntary and immediately available, 24/7/365.',
            })}
          </p>
          {
            efapThird && <p style={{ fontSize: '1.125rem', fontWeight: 300 }}>{efapThird}</p>
          }
        </div>
      </div>
    </div>
  );
};

SectionTwo.propTypes = {
  logo: PropTypes.string.isRequired,
  secondLogo: PropTypes.string,
  title: PropTypes.string.isRequired,
  EFAP: PropTypes.bool,
  minutes: PropTypes.number,
  intl: intlShape.isRequired,
  unlimitedSessions: PropTypes.string,
  unlimitedSessionsFr: PropTypes.string,
  subsequentSessions: PropTypes.string,
  subsequentSessionsFr: PropTypes.string,
  annuallyCovered: PropTypes.bool,
  frLogo: PropTypes.string,
  frTitle: PropTypes.string,
  removeSecondParagraph: PropTypes.bool,
  finalParagraph: PropTypes.string,
  finalParagraphFr: PropTypes.string,
  heures: PropTypes.string,
  efapSecond: PropTypes.string,
  efapSecondFr: PropTypes.string,
  efapThird: PropTypes.string,
};

SectionTwo.defaultProps = {
  minutes: null,
  EFAP: false,
  annuallyCovered: true,
  subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately.',
  subsequentSessionsFr: 'Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale ou payées en privé.',
  removeSecondParagraph: false,
  finalParagraph: null,
  finalParagraphFr: null,
  heures: null,
  unlimitedSessions: null,
  unlimitedSessionsFr: null,
  efapSecond: null,
  efapSecondFr: null,
  efapThird: null,
};

export default injectIntl(SectionTwo);
