/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'gatsby';
import Layout from './layout';
import { SERVER_URL } from '../utils/environment';
import '../styles/Pages/forgot_password/styles.scss';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      password_confirmation: '',
      errorMessage: null,
      successMessage: null,
    };
  }
  resetPassword = () => {
    const token = this.props.reset_password_token;
    const { password, password_confirmation } = this.state;
    axios
      .put(`${SERVER_URL}/api/v2/forgot_passwords/me?reset_password_token=${token}`, {
        password,
        password_confirmation,
      })
      .then(() => {
        this.setState({
          errorMessage: null,
          successMessage:
            'Password successfully reset! Please try logging in with your new credentials.',
        });
      })
      .catch(e => {
        let msg = 'Error';

        if (e.response && e.response.data && e.response.data.message) {
          msg = e.response.data.message;
        } else if (e.response && e.response.statusText) {
          msg = e.response.statusText;
        }

        this.setState({ errorMessage: msg, successMessage: null });
      });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event => {
    const { password, password_confirmation } = this.state;
    event.preventDefault();
    if (!password) {
      this.setState({ errorMessage: 'Please enter a password' });
      return;
    }
    if (password.length < 6) {
      this.setState({ errorMessage: 'Password not long enough' });
      return;
    }
    if (!password_confirmation) {
      this.setState({ errorMessage: 'Please confirm your password' });
      return;
    }
    if (password !== password_confirmation) {
      this.setState({ errorMessage: 'Passwords do not match.' });
      return;
    }

    this.resetPassword();
  };

  render() {
    const { errorMessage, successMessage } = this.state;

    return (
      <Layout location={this.props.location}>
        <div className="forgot-password">
          <div className="password-container">
            <h1>Password</h1>
            <p>Please enter a new password</p>
            <form onSubmit={this.handleSubmit}>
              <label htmlFor="password">Password</label>
              <input id="" name="password" type="password" onChange={this.handleChange} />
              <label htmlFor="password_confirmation">Confirm Password</label>
              <input name="password_confirmation" type="password" onChange={this.handleChange} />
              <button disabled={successMessage} type="submit">
                RESET PASSWORD
              </button>
            </form>
            {errorMessage && <p className="error">{errorMessage}</p>}
            {successMessage && (
              <div>
                <p className="success">{successMessage}</p>
                <Link to="/?signin=true">
                  <p style={{ color: '#2e5fca', fontWeight: 600 }} >Sign In</p>
                </Link>
              </div>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

ResetPassword.propTypes = {
  location: PropTypes.object,
  reset_password_token: PropTypes.string,
};

ResetPassword.defaultProps = {
  location: null,
  reset_password_token: '',
};

export default ResetPassword;
