import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import { SERVER_URL, THERAPY_URL } from '../../utils/environment';
import LogEventOnMount from '../LogEventOnMount';
import { OPEN_SIGNUP } from '../../utils/amplitude';
import { parseEapCodeFromPath } from '../../utils/pageUtils';

class IndigoSignup extends Component {
  constructor(props) {
    super(props);
    this.state = { dob: '', recentlyUpdated: null };
  }

  handleChange = event => {
    const { name, value } = event.target;
    if (name === 'email') {
      this.emailExists(value);
    }
    this.setState({
      [name]: value,
      [name]: name === 'dob' ? this.normalizeDate(value) : value,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.signUp();
  };

  emailExists = email => {
    axios
      .get(`${SERVER_URL}/api/signup/check_email?email=${email}`)
      .then(() => {
        this.setState({ emailError: false });
      })
      .catch(() => {
        this.setState({
          emailError: true,
        });
      });
  };

  signUp = () => {
    if (this.state.emailError) {
      return false;
    }
    axios
      .post(`${SERVER_URL}/api/signup`, {
        ...this.state,
        referral_source: 'indigo',
      })
      .then(response => {
        const token = response.data.access_token;
        const inkblotUrl = `${THERAPY_URL}/redirect/${token}`;
        window.location.replace(inkblotUrl);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
    return true;
  };

  normalizeDate = value => {
    if (!value) {
      return value;
    }
    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length <= 2) {
      return onlyNums;
    }
    if (onlyNums.length <= 4) {
      return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
    }
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
      4,
      8,
    )}`;
  };

  render() {
    return (
      <div className="custom-auth custom-dependants">
        <div className="dependants-container custom-auth-container">
          <div className="dependant-auth-form">
            <h2>
              {this.props.intl.formatMessage({
                id: 'eap.signup',
                defaultMessage: 'Sign up',
              })}
            </h2>
            <form onSubmit={this.handleSubmit}>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.firstName',
                    defaultMessage: 'First Name',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="first_name"
                  placeholder="John"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.lastName',
                    defaultMessage: 'Last Name',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="last_name"
                  placeholder="Doe"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.dob',
                    defaultMessage: 'Date of Birth',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="dob"
                  placeholder="31/12/1990"
                  value={this.state.dob}
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.email',
                    defaultMessage: 'Email',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="email"
                  placeholder="jdoe@example.com"
                />
                {this.state.emailError && (
                  <p className="error-text">Email Already Exists</p>
                )}
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.password',
                    defaultMessage: 'Password',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="password"
                  name="password"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.confirmPassword',
                    defaultMessage: 'Retype Password',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="password"
                  name="password"
                />
              </div>
              <div>
                <button type="submit">
                  {this.props.intl.formatMessage({
                    id: 'eap.signup',
                    defaultMessage: 'Sign Up',
                  })}
                </button>
              </div>
            </form>
            <h4>
              {this.props.intl.formatMessage({
                id: 'eap.alreadyHave',
                defaultMessage: 'Already have an account?',
              })}
              <Link to="/sign_in?eap=indigo" style={{ color: '#2a5ccd' }}>
                {this.props.intl.formatMessage({
                  id: 'eap.signin',
                  defaultMessage: 'Sign In',
                })}
              </Link>
            </h4>
          </div>
        </div>
        <LogEventOnMount
          eventType={OPEN_SIGNUP}
          eventProperties={{ page: parseEapCodeFromPath() }}
        />
      </div>
    );
  }
}

IndigoSignup.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(IndigoSignup);
