/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import SpinnerButton from '../SpinnerButton';
import InkblotLogo from '../../images/nav/inkblot-logo.svg';
import {
  logEvent,
  OPEN_CREATE_PASSWORD,
  setUserId,
} from '../../utils/amplitude';
import { normalizeDate } from '../../utils/form';

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: null,
    };
  }
  componentDidMount() {
    setUserId(this.props.userId);
    logEvent(OPEN_CREATE_PASSWORD, { page: this.props.source });
  }

  handleChange = event => {
    const { name, value } = event.target;

    this.props.values[name] = name === 'dob' ? normalizeDate(value) : value;
    this.props.validateForm();
    this.props.error.server = null;
  };

  renderHeader = () => {
    const lang = localStorage.getItem('selectedLanguage');
    if (lang === 'fr') {
      return (
        <h3>
          <FormattedMessage
            id="onboarding.password.createPassword"
            defaultMessage="Create Password"
          />
        </h3>
      );
    }
    return (
      <h2>
        <FormattedMessage
          id="onboarding.password.createPassword"
          defaultMessage="Create Password"
        />
      </h2>
    );
  };

  renderDisabled = () => {
    const { values, error } = this.props;

    if (!_.isEmpty(error)) {
      return true;
    }

    if (
      !values.password ||
      !values.password_confirmation ||
      !values.timezone ||
      !values.dob ||
      !values.gender ||
      (values.gender === 'custom' && !values.custom)
    ) {
      return true;
    }

    return false;
  };

  renderForm = () => {
    const {
      error, loading, intl, emailProvided, values,
    } = this.props;

    return (
      <form onSubmit={this.props.handleSubmit} style={{ marginBottom: 0 }}>
        <div className="password-input-row">
          <div>
            <h4>
              <FormattedMessage
                id="onboarding.password.preferredNameOptional"
                defaultMessage="Preferred Name (optional)"
              />
            </h4>
            <input
              onChange={value => this.handleChange(value)}
              type="text"
              name="preferred_name"
            />
            <p className={`error-text ${error.preferred_name ? '' : 'hidden'}`}>
              {error.preferred_name}
            </p>
          </div>
          <div>
            <h4>
              <FormattedMessage
                id="onboarding.password.dob"
                defaultMessage="Date of Birth"
              />
            </h4>
            <input
              onChange={value => this.handleChange(value)}
              name="dob"
              placeholder={intl.formatMessage({
                id: 'onboarding.password.dobPlaceholder',
                defaultMessage: 'DD/MM/YYYY',
              })}
              value={values.dob}
            />
            <p className={`error-text ${error.dob ? '' : 'hidden'}`}>
              {error.dob}
            </p>
          </div>
        </div>
        <div className="password-input-row">
          <div>
            <h4>
              <FormattedMessage
                id="onboarding.password.timezone"
                defaultMessage="Timezone"
              />
            </h4>
            <select
              className="dropdown"
              onChange={value => this.handleChange(value)}
              type="text"
              name="timezone"
              defaultValue=""
            >
              <option value="" />
              <option value="Pacific/Honolulu">
                {intl.formatMessage({
                  id: 'onboarding.password.ht',
                  defaultMessage: 'Hawaii‑Aleutian Time',
                })}
              </option>
              <option value="America/Juneau">
                {intl.formatMessage({
                  id: 'onboarding.password.ak',
                  defaultMessage: 'Alaskan Time',
                })}
              </option>
              <option value="America/Vancouver">
                {intl.formatMessage({
                  id: 'onboarding.password.pt',
                  defaultMessage: 'Pacific Time (Los Angeles/Vancouver)',
                })}
              </option>
              <option value="America/Edmonton">
                {intl.formatMessage({
                  id: 'onboarding.password.mt',
                  defaultMessage: 'Mountain Time (Denver/Edmonton)',
                })}
              </option>
              <option value="America/Winnipeg">
                {intl.formatMessage({
                  id: 'onboarding.password.ct',
                  defaultMessage: 'Central Time (Chicago/Winnipeg)',
                })}
              </option>
              <option value="America/Toronto">
                {intl.formatMessage({
                  id: 'onboarding.password.et',
                  defaultMessage: 'Eastern Time (New York/Toronto)',
                })}
              </option>
              <option value="America/Halifax">
                {intl.formatMessage({
                  id: 'onboarding.password.at',
                  defaultMessage: 'Atlantic Time (Halifax)',
                })}
              </option>
              <option value="America/Swift_Current">
                {intl.formatMessage({
                  id: 'onboarding.password.st',
                  defaultMessage: 'Central Standard Time (Saskatchewan)',
                })}
              </option>
              <option value="America/St_Johns">
                {intl.formatMessage({
                  id: 'onboarding.password.nt',
                  defaultMessage: 'Newfoundland Time',
                })}
              </option>
              <option value="MST">
                {intl.formatMessage({
                  id: 'onboarding.password.mst',
                  defaultMessage: 'Mountain Standard Time (Yukon)',
                })}
              </option>
              <option value="EST">
                {intl.formatMessage({
                  id: 'onboarding.password.est',
                  defaultMessage: 'Eastern Standard Time',
                })}
              </option>
            </select>
            <p className={`error-text ${error.timezone ? '' : 'hidden'}`}>
              {error.timezone}
            </p>
          </div>
          <div>
            <h4>
              <FormattedMessage
                id="onboarding.password.gender"
                defaultMessage="Gender"
              />
            </h4>
            <select
              className="dropdown"
              onChange={value => this.handleChange(value)}
              type="text"
              name="gender"
              defaultValue=""
            >
              <option value="" />
              <option value="male">
                {intl.formatMessage({
                  id: 'onboarding.password.male',
                  defaultMessage: 'Male',
                })}
              </option>
              <option value="female">
                {intl.formatMessage({
                  id: 'onboarding.password.female',
                  defaultMessage: 'Female',
                })}
              </option>
              <option value="custom">
                {intl.formatMessage({
                  id: 'onboarding.password.custom',
                  defaultMessage: 'Custom',
                })}
              </option>
              <option value="unspecified">
                {intl.formatMessage({
                  id: 'onboarding.password.unspecified',
                  defaultMessage: 'Prefer not to say',
                })}
              </option>
            </select>
            <p className={`error-text ${error.gender ? '' : 'hidden'}`}>
              {error.gender}
            </p>
          </div>
        </div>
        <div className="password-input-row">
          {values.gender === 'custom' && (
            <div>
              <h4>
                <FormattedMessage
                  id="onboarding.password.custom"
                  defaultMessage="Custom"
                />
              </h4>
              <input
                onChange={value => this.handleChange(value)}
                type="text"
                name="custom"
              />
              <p className={`error-text ${error.custom ? '' : 'hidden'}`}>
                {error.custom}
              </p>
            </div>
          )}
          {!emailProvided && (
            <div>
              <h4>
                <FormattedMessage
                  id="onboarding.password.email"
                  defaultMessage="Email"
                />
              </h4>
              <input
                onChange={value => this.handleChange(value)}
                type="text"
                name="email"
              />
            </div>
          )}
        </div>
        <div className="password-input-row">
          <div>
            <h4>
              <FormattedMessage
                id="onboarding.password.password"
                defaultMessage="Password"
              />
            </h4>
            <input
              onChange={value => this.handleChange(value)}
              type="password"
              name="password"
            />
            <p className={`error-text ${error.password ? '' : 'hidden'}`}>
              {error.password}
            </p>
          </div>
          <div>
            <h4>
              <FormattedMessage
                id="onboarding.password.confirmPassword"
                defaultMessage="Confirm Password"
              />
            </h4>
            <input
              onChange={value => this.handleChange(value)}
              type="password"
              name="password_confirmation"
            />
            <p
              className={`error-text ${
                error.password_confirmation ? '' : 'hidden'
              }`}
            >
              {error.password_confirmation}
            </p>
          </div>
        </div>
        <div className="form-buttons">
          <SpinnerButton
            label={
              <FormattedMessage
                id="onboarding.password.create"
                defaultMessage="Create"
              />
            }
            type="submit"
            loading={loading}
            disabled={this.renderDisabled()}
            style={{ marginTop: 40 }}
          />
          <p className={`error-text ${error.server ? '' : 'hidden'}`}>
            {error.server}
          </p>
          <button
            className="anchor-button"
            type="button"
            onClick={() => this.props.handlePrevious()}
          >
            <FormattedMessage
              id="onboarding.password.previous"
              defaultMessage="previous"
            />
          </button>
        </div>
      </form>
    );
  };

  render() {
    return (
      <div className="onboarding-auth">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 100,
            damping: 15,
          }}
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <div className="custom-onboarding-container custom-auth-text custom-password-reset">
            {this.renderHeader()}
            {this.renderForm()}
            <div className="custom-auth-logo">
              <img src={InkblotLogo} alt="inkblot logo" />
            </div>
          </div>
        </motion.div>
      </div>
    );
  }
}

PasswordReset.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool,
  source: PropTypes.string,
  userId: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  emailProvided: PropTypes.bool,
};

PasswordReset.defaultProps = {
  error: {},
  loading: false,
  source: undefined,
  emailProvided: false,
};

export default injectIntl(PasswordReset);
