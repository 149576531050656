import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import _ from 'lodash';

import SessionTwo from '../../images/custom/illustrations/session-2.svg';
import InkblotLogo from '../../images/nav/inkblot-logo.svg';
import { SERVER_URL, THERAPY_URL } from '../../utils/environment';
import {
  parseEapCodeFromPath,
  unauthAuditProgress,
} from '../../utils/pageUtils';
import LogEventOnMount from '../LogEventOnMount';
import { OPEN_SIGNIN } from '../../utils/amplitude';
import SpinnerButton from '../SpinnerButton';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
    };

    this.eapCode = parseEapCodeFromPath();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.clearError, false);
    unauthAuditProgress(this.eapCode, 'open_signin');
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.clearError, false);
  }

  clearError = () => {
    this.setState({ error: null });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.signIn();
  };

  // copied from src/components/auth/SignIn.js
  signIn = () => {
    this.setState({ loading: true, error: null });
    const values = _.omit(this.state, ['error']);

    axios
      .post(`${SERVER_URL}/api/v2/forgot_passwords/atb_login`, { ...values, source: 'therapy' })
      .then(mainResponse => {
        // success in DB
        const token = mainResponse.data.access_token;
        const inkblotUrl = `${THERAPY_URL}/redirect/${token}`;
        window.location.replace(inkblotUrl);
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
        this.setState({ error: 'The employee id or password you provided is incorrect.' });
      });
  };

  renderBottom = () => (
    <div>
      <h4>
        {this.props.intl.formatMessage({
          id: 'eap.dontHave',
          defaultMessage: "Don't have an account? ",
        })}{' '}
        <Link
          to={`/sign_up?eap=${this.eapCode}`}
          style={{ color: '#2a5ccd' }}
        >
          {this.props.intl.formatMessage({
            id: 'eap.signUp',
            defaultMessage: 'Sign Up',
          })}
        </Link>
      </h4>
      <h4>
        <Link
          to="/forgot_password"
          style={{ color: '#2a5ccd' }}
        >
          {this.props.intl.formatMessage({
            id: 'auth.forgotMyPassword',
            defaultMessage: 'Forgot My Password',
          })}
        </Link>
      </h4>
    </div>
  );

  render() {
    const { error, loading } = this.state;
    const language = localStorage.getItem('selectedLanguage');
    return (
      <div className="custom-auth">
        <div className="auth-container custom-auth-container">
          <div className="custom-auth-text">
            <h2>
              {this.props.intl.formatMessage({
                id: 'eap.signin',
                defaultMessage: 'Sign In',
              })}
            </h2>
            <form onSubmit={this.handleSubmit}>
              <h4>
                {this.props.intl.formatMessage({
                  id: 'eap.employeeId',
                  defaultMessage: language === 'fr' ? 'Numéro d’employé(e)' : 'Employee ID',
                })}
              </h4>
              <input
                onChange={value => this.handleChange(value)}
                type="text"
                name="employee_id"
                placeholder={
                  language === 'fr' ? 'Votre numéro d’employé(e)' : 'Your Employee ID'
                }
              />
              <h4>
                {this.props.intl.formatMessage({
                  id: 'eap.password',
                  defaultMessage: 'Password',
                })}
              </h4>
              <input
                onChange={value => this.handleChange(value)}
                type="password"
                name="password"
                placeholder={
                  language === 'fr' ? 'Votre mot de passe' : 'Your Password'
                }
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 240,
                  marginTop: 40,
                }}
              >
                <SpinnerButton
                  label={this.props.intl.formatMessage({
                    id: 'eap.signin',
                    defaultMessage: 'Sign In',
                  })}
                  type="submit"
                  loading={loading}
                />
              </div>
            </form>
            <p
              className={`error-text ${error ? '' : 'hidden'}`}
              style={{ width: 288, color: 'red' }}
            >
              {error}
            </p>
            {this.renderBottom()}
          </div>
          <img src={SessionTwo} alt="session two" />
          <div className="custom-auth-logo">
            <img src={InkblotLogo} alt="inkblot logo" />
          </div>
        </div>
        <LogEventOnMount
          eventType={OPEN_SIGNIN}
          eventProperties={{ page: this.eapCode }}
        />
      </div>
    );
  }
}

SignIn.propTypes = {
  intl: PropTypes.object,
};

SignIn.defaultProps = {
  intl: {},
};

export default injectIntl(SignIn);
