import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import { COUNTRY } from '../../utils/environment';
import teamworkWhite9 from '../../images/custom/illustrations/teamwork-white-9.svg';

const SectionSix = props => {
  const {
    usNotFree, path, intl, isDMZ,
  } = props;
  return (
    <div className="section-6">
      <img src={teamworkWhite9} alt="" />
      <div>
        <h2>
          {intl.formatMessage({ id: 'eap.feelBetter', defaultMessage: 'Feel Better,' })}{' '}
          <span>{intl.formatMessage({ id: 'eap.liveBetter', defaultMessage: 'Live Better,' })}</span>
        </h2>
        <p>
          {intl.formatMessage({ id: 'eap.bestWay', defaultMessage: 'Counselling is the best way to achieve mental well-being, learn to cope with work and life stressors and improve your relationships.' })}
        </p>
        <h4>{usNotFree && COUNTRY === 'US' ? intl.formatMessage({ id: 'eap.usBookFirst', defaultMessage: 'Book your first session today:' }) : intl.formatMessage({ id: 'eap.bookFirstFree', defaultMessage: 'Book your first session today:' })}</h4>
        <Link
          title="Get Started"
          to={isDMZ ? '/sign_up?eap=dmz' : `/sign_up?eap=${path}`}
          className="custom-button-blue"
        >
          <FormattedMessage id="eap.getStarted" defaultMessage="Get Started" style={{ padding: 0 }} />
        </Link>
      </div>
    </div>
  );
};

SectionSix.propTypes = {
  path: PropTypes.string.isRequired,
  intl: PropTypes.object,
  isDMZ: PropTypes.bool,
  usNotFree: PropTypes.bool,
};

SectionSix.defaultProps = {
  intl: {},
  isDMZ: false,
  usNotFree: false,
};

export default injectIntl(SectionSix);
