import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import step1 from '../../images/custom/screens/step-1.png';
import step2 from '../../images/custom/screens/Customcounsellormatchcard.svg';
import step3 from '../../images/custom/screens/step-3.png';
import step4 from '../../images/custom/screens/step-4.png';
import french4 from '../../images/custom/screens/group2.png';
import french3 from '../../images/custom/screens/group-1.svg';
import french2 from '../../images/custom/screens/Customcounsellormatchcardfrench.svg';
import french1 from '../../images/custom/screens/Group8.png';
import { COUNTRY } from '../../utils/environment';
import { whichCompany } from '../../utils/pageUtils';

const SectionThree = (props) => {
  const { usNotFree } = whichCompany();
  const featureData = [
    {
      step: '1.',
      id: 'eap.shortQuestionnaire',
      title: 'Short Questionnaire',
      description: 'Fill out our matching survey to help you connect to a provider best suited to your needs. You can match based on your symptoms, stressors, and even language and religion.',
      descriptionId: 'eap.fillOut',
      image: step1,
      frImage: french1,
    },
    {
      step: '2.',
      id: 'eap.customCounsellor',
      title: 'Custom Counsellor Match',
      descriptionId: 'eap.afterQuestionnaire',
      description: 'After the questionnaire, you’ll be directed to a provider selection page where matches will be ranked based on effectiveness and your individual needs.',
      image: step2,
      frImage: french2,
    },
    {
      step: '3.',
      id: (usNotFree && COUNTRY === 'US') ? 'eap.firstFree' : 'eap.caFirstFree',
      title: 'Book First Free Session',
      descriptionId: 'eap.foundProvider',
      description: 'When you’ve found your provider, you’ll be able to book your initial session.',
      image: step3,
      frImage: french3,
    },
    {
      step: '4.',
      id: 'eap.confidential',
      title: 'Confidential video sessions any time, any place',
      descriptionId: 'eap.enjoySecure',
      description: 'Enjoy secure sessions from the comfort of your own space and start feeling better.',
      image: step4,
      frImage: french4,
    },
  ];
  return (
    <div className="section-3">
      <div className="accessing-care">
        <h2>{props.intl.formatMessage({ id: 'eap.accessCare', defaultMessage: 'Accessing care is easy.' })}</h2>
      </div>
      <div className="feature-list">
        {
          featureData.map((feature) => {
            const {
              title, description, image, frImage, step, id, descriptionId,
            } = feature;
            return (
              <div className="feature-card" key={title}>
                { localStorage.getItem('selectedLanguage') === 'fr' ? (
                  <img src={frImage} alt={title} />
                ) : (<img src={image} alt={title} />)}
                <div className="step-title">
                  <span>{step}</span>
                  <span>{props.intl.formatMessage({ id, defaultMessage: title })}</span>
                </div>
                <p>
                  {props.intl.formatMessage({
                    id: descriptionId, defaultMessage: description,
                  })}
                </p>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

SectionThree.propTypes = {
  intl: PropTypes.object,
};

SectionThree.defaultProps = {
  intl: {},
};

export default injectIntl(SectionThree);
