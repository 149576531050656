import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { scroller, Element } from 'react-scroll';
import Bimbo from '../../images/people-connect-total-health/bimbo-canada-logo.png';
import TotalHealth from '../../images/people-connect-total-health/total-health.jpg';
import SanteGlobale from '../../images/people-connect-total-health/sante-globale.jpg';

import AspiriaEN from '../../images/people-connect-total-health/aspiria-logo.png';
import AspiriaFR from '../../images/people-connect-total-health/aspiria-logo-fr.jpeg';
import People from '../../images/people-connect-total-health/people-logo-two.png';
import Maple from '../../images/people-connect-total-health/maple.png';
import Pocket from '../../images/people-connect-total-health/pocket-pills.png';

const Index = () => {
  const setLanguage = lang => {
    localStorage.setItem('selectedLanguage', lang);
    window.location.reload();
  };
  const Aspiria = localStorage.getItem('selectedLanguage') === 'fr' ? AspiriaFR : AspiriaEN;
  const scrollToElement = element => {
    scroller.scrollTo(element, {
      duration: 1500,
      smooth: 'easeInOutQuart',
    });
  };
  return (
    <div className="bimbo">
      <div className="section-1">
        <div className="en-fr">
          {
            localStorage.getItem('selectedLanguage') === 'fr' ?
              <div>
                <span role="presentation" onClick={() => setLanguage('en')} style={{ fontWeight: 300 }}>
                  EN
                </span>
                <span>
                  FR
                </span>
              </div>
              :
              <div>
                <span>
                  EN
                </span>
                <span role="presentation" onClick={() => setLanguage('fr')} style={{ fontWeight: 300 }}>
                  FR
                </span>
              </div>
            }
        </div>
        <div className="logos">
          <img src={Bimbo} alt="Bimbo Logo" />
          <img src={localStorage.getItem('selectedLanguage') === 'fr' ? SanteGlobale : TotalHealth} alt="Total Health" />
        </div>
        <FormattedHTMLMessage tagName="h2" id="bimbo.helpingYou" defaultMessage="Helping you take care of your physical and mental health" />
        <div className="divider" />
        <FormattedHTMLMessage tagName="h3" id="bimbo.totalHealth" defaultMessage="Total Health is a complete virtual health care solution, that includes access to doctors, Employee Assistance Program (EAP), enhanced mental health care and an online pharmacy, making it easy for associates to get the treatment they need from the comfort and safety of their own homes." />
        <FormattedHTMLMessage tagName="h4" id="pch.yourProviders" />
        <div className="bimbo-company-container">
          <div className="aspiria" onClick={() => scrollToElement('aspiria')} role="button" tabIndex={0} onKeyPress={() => scrollToElement('aspiria')}>
            <img src={Aspiria} alt="Aspiria logo" />
          </div>
          <div className="people" onClick={() => scrollToElement('people')} role="button" tabIndex={0} onKeyPress={() => scrollToElement('people')}>
            <img src={People} alt="People" />
          </div>
          <div className="maple" onClick={() => scrollToElement('maple')} role="button" tabIndex={0} onKeyPress={() => scrollToElement('maple')}>
            <img src={Maple} alt="Maple" />
          </div>
          <div className="pocket" onClick={() => scrollToElement('pocket')} role="button" tabIndex={0} onKeyPress={() => scrollToElement('pocket')}>
            <img src={Pocket} alt="Pocket" />
          </div>
        </div>
      </div>
      <div className="section-2">
        <div className="container">
          <div className="left">
            <FormattedHTMLMessage
              tagName="h2"
              id="bimbo.eap"
              defaultMessage="Employee Assistance Program"
            />
            <FormattedHTMLMessage
              tagName="h1"
              id="bimbo.aspiria"
              defaultMessage="Aspiria"
            />
            <FormattedHTMLMessage
              tagName="p"
              id="bimbo.aspiriasEap"
              defaultMessage="Aspiria’s EAP (Employee Assistance Program) provides 24/7 services for associates and their dependent family members mental, emotional, physical, and financial health.<br><br>Counselling services include:<br><ul><li>referrals</li><li>face to face (where available)</li><li>telephonic</li><li>video</li><li>e-counselling</li></ul><br>In addition, Aspiria provides:<br><ul><li>daily living consultations from pet care to managing day-to-day responsibilities at home and work</li><li>wellness coaching to address sleep, nutrition, stress and physical activity</li><li>online support including articles on health and wellness topics, Covid-19 resources and pre-recorded seminars</li></ul>"
            />
            <div className="divider" />
            <FormattedHTMLMessage
              tagName="p"
              id="bimbo.tollFree"
              defaultMessage="Call toll free 24 Hours: 1.833.613.0968<br><br>Access our website at <a href='http://aspiriaeap.resourcesforyourlife.com'>AspiriaEAP.resourcesforyourlife.com</a><br>Company code: BimboCanada<br>Password: BimboCanada<br><br>iConnectYou mobile app company passcode: 195190<br><br>Learn about EAP services:<br><a>Poster</a><br><a>Video</a>"
            />
          </div>
          <div className="right">
            <Element name="aspiria">
              <div className="bci-white-box">
                <img src={Aspiria} alt="Aspiria" />
              </div>
            </Element>
          </div>
        </div>
      </div>
      <div className="section-3">
        <div className="container">
          <div className="left">
            <Element name="people">
              <div className="bci-white-box">
                <img src={People} alt="People Connect" />
              </div>
            </Element>
          </div>
          <div className="right">
            <FormattedHTMLMessage
              tagName="h2"
              id="bimbo.virtual"
              defaultMessage="Virtual Health"
            />
            <FormattedHTMLMessage
              tagName="h1"
              id="bimbo.peopleConnect"
              defaultMessage="People Connect"
            />
            <FormattedHTMLMessage
              tagName="p"
              id="bimbo.thisService"
              defaultMessage="This service provides associates and family members with educational resources and access to immediate care through video counselling. It includes a full diagnostic assessment tool for associates to better understand their mental health , we include consultation with a psychiatrist in a virtual collaboration meeting. People Connect utilizes the coverage provided under your group benefits plan making it easy to get professional and affordable therapy. There is no charge for the first 30 minute appointment. Plan members can claim the costs of additional sessions if they have paramedical coverage or by using the HCSA of their group benefits plan."
            />
            <div className="divider" />
            <FormattedHTMLMessage
              tagName="p"
              id="bimbo.clickHere"
              defaultMessage="<a>Click here to get started</a><br><br>Type Bimbo in the company field and then select Bimbo Canada from the drop down.<br><br><a>Learn more about People Connect</a><br><br>If you have questions about the app or service: <a>support@pcpeopleconnect.com</a>"
            />
          </div>
        </div>
      </div>
      <div className="section-2" id="#maple">
        <div className="container">
          <div className="left">
            <FormattedHTMLMessage
              tagName="h2"
              id="bimbo.virtual"
              defaultMessage="Virtual Health"
            />
            <FormattedHTMLMessage
              tagName="h1"
              id="bimbo.maple"
              defaultMessage="Maple"
            />
            <FormattedHTMLMessage
              tagName="p"
              id="bimbo.mapleIs"
              defaultMessage="Maple is a virtual health app providing associates and their family members quick and easy access to general physicians (including emergency room and family physicians).  Specialist appointments can be booked for an additional fee. Maple offers an upfront online assessment, followed by virtual appointments with doctors, on-demand, 24/7.<br><br>You have 2 consults with a general practitioner per family member per calendar year."
            />
            <div className="divider" />
            <FormattedHTMLMessage
              tagName="p"
              id="bimbo.getStarted"
              defaultMessage="<a>Click here to get started</a><br><br>You will require your Oracle ID to register for this program. If you don't know your Oracle ID, please ask your supervisor/manager or email <a>bimbocanada@peoplecorporation.com.</a><br><br>Download the Maple app to contact a doctor.<br><br><a>Learn more about Maple</a><br><a>Learn how to get set up</a><br>Having trouble with the app: <a>support@getmaple.ca</a>"
            />
          </div>
          <div className="right">
            <Element name="maple">
              <div className="bci-white-box">
                <img src={Maple} alt="Maple" />
              </div>
            </Element>
          </div>
        </div>
      </div>
      <div className="section-5">
        <div className="container">
          <div className="left">
            <Element name="pocket">
              <div className="bci-lavender-box">
                <img src={Pocket} alt="PocketPills" />
              </div>
            </Element>
          </div>
          <div className="right">
            <FormattedHTMLMessage
              tagName="h2"
              id="bimbo.pharmacy"
              defaultMessage="Pharmacy"
            />
            <FormattedHTMLMessage
              tagName="h1"
              id="bimbo.pocketPills"
              defaultMessage="PocketPills"
            />
            <FormattedHTMLMessage
              tagName="p"
              id="bimbo.onlinePharmacy"
              defaultMessage="An Online Pharmacy service to help you get your medications without leaving your home.<br><br>* Save -15% on Drug Costs (not available in Quebec)<br><br><ul><li>Low dispensing fee of $7.00, low markup below provincial rates</li><li>24/7 easy access to pharmacists via live chat, SMS, email, and phone</li><li>PocketPacks - easy to tear and take</li><li>Manage medication for dependents, additional family and others</li><li>FREE delivery</li></ul><br>Improve your health by learning about your drug and diagnosis."
            />
            <div className="divider" />
            <FormattedHTMLMessage
              tagName="p"
              id="bimbo.helloRx"
              defaultMessage="If you wish to register and transfer existing prescriptions from another pharmacy: Please have a picture of the front and the back of your primary insurance card, your health card and your existing pharmacy information ready. If you do not wish to transfer prescriptions right away, you do not need this information.  Please leave “Referral Code” blank.<br><br><a>Click here to get started</a><br><br>If you need support for the technology or service: 1-833-HELLORX<br><br><a>Learn more about PocketPills</a>"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
