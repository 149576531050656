import bookLarge from '../../images/_booklarge.svg';
import match from '../../images/_match.svg';
import connect from '../../images/_connect.svg';

export const circleData = [
  {
    id: 1,
    title: 'MATCH',
    image: match,
    description: 'Complete our simple evaluation and get matched with a counsellor.',
  },
  {
    id: 2,
    title: 'BOOK',
    image: bookLarge,
    description: 'Book your first free appointment with wait times of less than two weeks.',
  },
  {
    id: 3,
    title: 'CONNECT',
    image: connect,
    description: 'Chat with your counsellor in the comfort of your own home, and feel better.',
  },
];

export const securityData = [
  'All information follows Canadian regulations including PIPEDA and PHI.',
  'All information is military grade encrypted on an audited server in Toronto.',
  'Employers, administrators, and referral sources do not have access to any of your mental health information.',
  'All therapists reside in Canada and are regulated professionals.',
];

export const testimonials = [
  "With month-long wait times at my university, my sessions weren't therapeutic - the therapist couldn’t see me often enough  to follow my progress. At my first session with Inkblot, my counsellor asked if she could make more time to see me in addition to her already daily availability.",

  "Inkblot is the perfect format for me. I don't have to worry about driving and parking but rather just sit in the comfort of my own home at a time that suits me.",

  "I feel like I'm meeting myself all over again through therapy, and it's amazing. My anxiety had turned me into a person I didn't recognize. \n After my latest session, I was finally able to do some things that I'd been putting off because I wasn't feeling confident or motivated.",

  'After I had my son there were many moments that I just had such a hard time coping. I’m glad I followed my gut and got some help.\n Inkblot is an amazing resource for parents. I tell all my mom friends about it!',
];
