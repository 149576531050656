import arashlarge from '../../images/about/people/arash.png';
import lukelarge from '../../images/about/people/luke.png';
import barbara from '../../images/about/Barbara.jpg';
import todd from '../../images/about/Todd.jpg';
import chris from '../../images/about/Chris.jpg';
import theresa from '../../images/about/Theresa.jpg';
import bonnie from '../../images/about/Bonnie.jpg';

export const aboutData = [
  {
    id: 1,
    locale: 'about.lifeIsHard',
    text: 'Life is filled with uncertainty, complexity, and ambiguity. Overwhelmed, people endure in silence: feeling empty, withdrawn, and unsettled without sufficient supports.',
    maxWidth: '525px',
  },
  {
    id: 2,
    locale: 'about.inkblotMotivated',
    text: '<span class="regular">At Inkblot, we aim to help you feel motivated and at your best.</span>',
    maxWidth: '525px',
  },
  {
    id: 3,
    locale: 'about.groundApproach',
    text:
      'We ground our approach in the belief that mental health services should be convenient, affordable and confidential. Above all, we aim to provide the highest quality personalized care.',
    maxWidth: '735px',
  },
  {
    id: 4,
    locale: 'about.advancedTech',
    text:
      'Inkblot uses advanced technologies for intelligent matching, monitoring effectiveness, and eliminating geographical barriers. At the same time, we maximize the human connection between care providers and clients. <span class="regular">We believe this harmony is the key to our success.</span>',
    maxWidth: '840px',
  },
  {
    id: 5,
    locale: 'about.ourMission',
    text: 'Our mission is to help you have a more meaningful, connected and joyful life.',
    maxWidth: '840px',
  },
];

export const bossData = [
  {
    id: 'luke',
    name: 'Luke Vigeant',
    title: 'Co-founder & CEO',
    text:
      'Luke is a technology entrepreneur with a focus on taking products from concept to market. Outside of his co-founder role at Inkblot, Luke also co-founded tilr, a marketplace application powered by an algorithmic hiring engine, where he has held the titles of CPO and CTO. He has also held titles as CTO and product strategist at Evolution Optiks. Prior to entering the start-up ecosystem Luke was a consultant at IBM Global Business Services. Luke is a graduate of Saint Francis Xavier University and was the Senior Class Speaker for his year.',
    image: lukelarge,
  },
  {
    id: 'arash',
    name: 'Dr. Arash Zohoor',
    title: 'Co-founder & CPO',
    text:
      'Arash is a community family and emergency physician in Brantford, Ontario. He has a special focus on mental health, addictions and chronic pain and is frequently a guest lecture on these topics across the country. He is a clinical professor at McMaster University. He has been in multiple healthcare leadership roles and has won numerous leadership awards including the Ontario College of Family Medicine Award of Excellence. Arash is a graduate of the University of Toronto and studied medicine at Schulich School of Medicine and McMaster University.',
    image: arashlarge,
  },
];

export const practitionerData = [
  {
    id: 1,
    locale: 'todd',
    name: 'Todd Solomon',
    degree: 'RP',
    image: todd,
  },
  {
    id: 2,
    locale: 'barbara',
    name: 'Barbara Mills',
    degree: 'MEd, RMFT, RP',
    image: barbara,
  },
  {
    id: 3,
    locale: 'chris',
    name: 'Chris Lindsay',
    degree: 'MEd, RSW',
    image: chris,
  },
  {
    id: 4,
    locale: 'theresa',
    name: 'Theresa Fraser',
    degree: 'MC, RP',
    image: theresa,
  },
  {
    id: 5,
    locale: 'bonnie',
    name: 'Bonnie J Skinner',
    degree: 'MC, RP',
    image: bonnie,
  },
];
