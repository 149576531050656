import React from 'react';
import _ from 'lodash';
import SectionContainer from '../../components/SectionContainer';
import { faqData } from '../../pagePartials/faq';
import FaqDropdown from '../../pagePartials/gallivan/FaqDropdown';

const Faq = () => (
  <div className="gallivan-faq-page">
    <SectionContainer container num={1}>
      <h1>FAQ</h1>
    </SectionContainer>
    <SectionContainer container num={2}>
      {_.map(faqData, ({ id, question, answer }) => (
        <FaqDropdown key={id} question={question} answer={answer} />
      ))}
    </SectionContainer>
  </div>
);

export default Faq;
