import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';
import _ from 'lodash';
import Layout from '../components/layout';

import CustomLandingPage from '../components/custom';
import CorporateNavPage from '../pagePartials/corporate-nav-page';

import SignUp from '../components/custom/SignUp';
import SignIn from '../components/custom/SignIn';
import Dependants from '../components/custom/Dependants';

import {
  whichCompany,
  isCorporateLanding,
  isGallivanPage,
  isSunLife,
  isLeagueX,
  isMLF,
  isLeslie,
  isFullSignUp,
  isPartialSignUp,
  isNoneSignUp,
  isFullyGatedSignUp,
  isOmers,
  isPPCHealthAkira,
  isPPCHealthMaple,
  isCSA,
  isPPCHealthLifeInc,
  isPPCHealthBCI,
  isATB,
  isPPCHealthBimbo,
  isCorporateNav,
} from '../utils/pageUtils';
import DMZSignup from '../components/custom/DMZSignup';
import WellSignUp from '../components/custom/WellSignup';
import EsgSignUp from '../components/custom/EsgSignUp';
import DomainOnlySignUp from '../components/custom/DomainOnlySignUp';
import FullSignUp from '../components/custom/FullSignUp';
import PartialSignUp from '../components/custom/PartialSignUp';
import NoneSignUp from '../components/custom/NoneSignUp';
import FullyGatedSignUp from '../components/custom/FullyGatedSignUp';
import ATBSignUp from '../components/custom/ATBSignup';
import ATBSignIn from '../components/custom/ATBSignin';
import EmployeeIdSignUp from '../components/custom/EmployeeIdSignup';

import GallivanIndex from '../components/gallivan/';
import GallivanAbout from '../components/gallivan/About';
import GallivanFaq from '../components/gallivan/Faq';
import schools from '../pagePartials/gallivan/schools';

import SunLifeIndex from '../components/sunlife/';
import LeagueIndex from '../components/League';
import LeslieIndex from '../components/Leslie';
import OmersIndex from '../components/Omers';
import ResetPassword from '../components/reset_password';
import Unsubscribe from './unsubscribe';
import IndigoSignup from '../components/custom/IndigoSignup';
import EligibilityWelcome from '../components/eligibility';

import MLF from '../components/mlf';
import MLFSignup from '../components/custom/MLFSignup';
import Akira from '../components/people_connect_total_health/akira';
import Maple from '../components/people_connect_total_health/maple';
import CSA from '../components/people_connect_total_health/csa';
import LifeInc from '../components/people_connect_total_health/lifeinc';
import BCI from '../components/people_connect_total_health/bci';
import ATB from '../components/people_connect_total_health/atb';
import Bimbo from '../components/people_connect_total_health/bimbo';
import Subprocessors from './subprocessors';

const App = props => (
  <Layout location={props.location}>
    <Router>
      {isCorporateLanding(props.location.pathname) && (
        <CustomLandingPage path={`/${props.location.pathname}`} />
      )}

      {isCorporateNav(props.location.pathname) && (
        <CorporateNavPage path={`/${props.location.pathname}`} />
      )}

      {whichCompany().path === 'indigo' && <IndigoSignup path="/sign_up" />}
      {whichCompany().path === 'dmz' && <DMZSignup path="/sign_up" />}
      {whichCompany().path === 'thesweetpotato' && (
        <DMZSignup path="/sign_up" />
      )}
      {whichCompany().path === 'mapleleaffoods' && (
        <MLFSignup path="/sign_up" />
      )}
      {whichCompany().path === 'cbn' && <DMZSignup path="/sign_up" />}
      {whichCompany().path === 'eastcoaststrong' && (
        <DMZSignup path="/sign_up" />
      )}
      {whichCompany().path === 'esg' && <EsgSignUp path="/sign_up" />}
      {whichCompany().path === 'well' && <WellSignUp path="/sign_up" />}
      {whichCompany().path === 'tps' && <DomainOnlySignUp path="/sign_up" />}
      {whichCompany().path === 'atb' && <ATBSignUp path="/sign_up" />}
      {whichCompany().path === 'atb' && <ATBSignIn path="/sign_in" />}
      {whichCompany().employeeIdSignup && <EmployeeIdSignUp path="/sign_up" />}
      {whichCompany().employeeIdSignup && <ATBSignIn path="/sign_in" />}

      {isFullSignUp(props.location.pathname) && <FullSignUp path="/sign_up" />}
      {isPartialSignUp(props.location.pathname) && (
        <PartialSignUp path="/sign_up" />
      )}
      {isNoneSignUp(props.location.pathname) && <NoneSignUp path="/sign_up" />}
      {isFullyGatedSignUp(props.location.pathname) && <FullyGatedSignUp path="/sign_up" />}

      {isGallivanPage(props.location.pathname) &&
        _.map(schools, school => <GallivanIndex path={`/${school.key}`} />)}
      {isGallivanPage(props.location.pathname) &&
        _.map(schools, school => (
          <GallivanAbout path={`/${school.key}/about`} />
        ))}
      {isGallivanPage(props.location.pathname) &&
        _.map(schools, school => <GallivanFaq path={`/${school.key}/faq`} />)}

      {isSunLife(props.location.pathname) && <SunLifeIndex path="/sunlife" />}
      {isLeagueX(props.location.pathname) && <LeagueIndex path="/leaguex" />}
      {isMLF(props.location.pathname) && <MLF path="/mlf" />}
      {isPPCHealthAkira(props.location.pathname) && (
        <Akira path="/ppc_health_akira" />
      )}
      {isPPCHealthMaple(props.location.pathname) && (
        <Maple path="/ppc_health_maple" />
      )}
      {isPPCHealthLifeInc(props.location.pathname) && (
        <LifeInc path="/ppc_health_life_inc" />
      )}
      {isPPCHealthBCI(props.location.pathname) && (
        <BCI path="/ppc_health_bci" />
      )}
      {isPPCHealthBimbo(props.location.pathname) && (
        <Bimbo path="/ppc_health_bimbo" />
      )}
      {isCSA(props.location.pathname) && <CSA path="/csa_land" />}
      {isATB(props.location.pathname) && <ATB path="/atb_land" />}
      {isLeslie(props.location.pathname) && <LeslieIndex path="/care" />}
      {isOmers(props.location.pathname) && <OmersIndex path="/omers" />}

      <ResetPassword path="/reset_password/:reset_password_token" />
      <EligibilityWelcome path="/onboarding/:eligibility_token" />
      <SignUp path="/sign_up" />
      <SignIn path="/sign_in" />
      <Dependants path="/dependants" />
      <Unsubscribe path="/unsubscribe" />
      <Subprocessors path="/subprocessors" />
    </Router>
  </Layout>
);

App.propTypes = {
  location: PropTypes.object.isRequired,
};

export default App;
