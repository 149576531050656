import React from 'react';
import Carousel from 'nuka-carousel';
import _ from 'lodash';
import { testimonials } from './index';

const Slider = () => (
  <Carousel autoplay autoplayInterval={10000}>
    {_.map(testimonials, (t, i) => (
      <div className="slider-content" key={i}>
        <p>{t}</p>
      </div>
    ))}
  </Carousel>
);

export default Slider;
