import React from 'react';
import _ from 'lodash';
import SectionContainer from '../../components/SectionContainer';
import macBook from '../../images/_MacBook-Pro.png';
import check from '../../images/_check.svg';
import { circleData, securityData } from '../../pagePartials/index/';
import CompareTable from '../../pagePartials/index/CompareTable';
import CompareTableMobile from '../../pagePartials/index/CompareTableMobile';
import Slider from '../../pagePartials/index/Slider';
import AsSeenOn from '../../pagePartials/index/AsSeenOn';

const Index = () => (
  <div className="index-page">
    <SectionContainer style={{ paddingTop: '160px', paddingLeft: '120px' }} num={1}>
      <div className="row">
        <div className="emoji-container" style={{ top: '-50px' }} />
        <div className="col-6">
          <h3 style={{ fontSize: '43px' }}>Welcome Sun Life digital health community members.</h3>
          <h4 style={{ fontSize: '20px' }}>Get 10% off your first paid session by using promo code SunLife.</h4>
          <div style={{ marginTop: '55px' }}>
            <a
              href="https://app.inkblottherapy.com/signup?affiliation=sunlife"
              className="button-transparent-white-border"
            >
              GET STARTED
            </a>
          </div>
        </div>
        <div
          style={{ marginRight: '0', marginTop: '-50px', float: 'right' }}
          className="col-6 mac-book"
        >
          <img
            style={{ float: 'right', maxWidth: '700px', maxHeight: '500px' }}
            src={macBook}
            alt="macbook"
          />
        </div>
      </div>
    </SectionContainer>
    <SectionContainer container style={{ paddingTop: '160px' }} num={2}>
      <h2>Relief is only a few steps away</h2>
      <div style={{ marginTop: '70px' }} className="row center ">
        {_.map(circleData, ({ title, image, description }, i) => (
          <div key={i} className="col-4">
            <div className="circle-container ">
              <div className="info-circle">
                <img src={image} alt={title} />
              </div>
              <h3 style={{ marginTop: '35px' }}>{title}</h3>
              <p>{description}</p>
            </div>
          </div>
        ))}
      </div>
    </SectionContainer>
    <SectionContainer container style={{ paddingTop: '40px', paddingBottom: '140px' }} num={3}>
      <div className="center">
        <iframe
          title="inkblot-promo"
          src="https://player.vimeo.com/video/273366215"
          width="100%"
          height="480"
          frameBorder="0"
          allowFullScreen
        />
      </div>
      <div style={{ marginTop: '70px', maxWidth: '700px' }} className="center">
        <h3>
          It&#39;s been very frustrating seeing my patients with mental health difficulties and
          addictions suffer and not get the help they need.{' '}
          <span>
            Wait lists are too long and private counselling is expensive and of uncertain quality.
          </span>
        </h3>
        <h4>Dr. Arash Zohoor, Co-Founder & CEO</h4>
      </div>
    </SectionContainer>
    <SectionContainer container style={{ paddingTop: '120px', paddingBottom: '140px' }} num={4}>
      <div className="center">
        <h2>Your security is our top priority</h2>
        <div style={{ marginTop: '50px' }} className="security-box">
          {_.map(securityData, (data, i) => (
            <p key={i}>
              <img style={{ marginRight: '12px' }} src={check} alt="check" />
              {data}
            </p>
          ))}
        </div>
      </div>
    </SectionContainer>
    <SectionContainer container style={{ paddingBottom: '140px' }} num={5}>
      <h2>We are the most affordable option</h2>
      <p style={{ maxWidth: '600px' }}>
        By automating administrative tasks, we significantly reduce overhead costs. This allows the
        counsellors to charge half of their usual office prices.
      </p>
      <CompareTable />
      <CompareTableMobile />
    </SectionContainer>
    <SectionContainer container num={6}>
      <h2
        style={{
          marginBottom: '72px',
          fontWeight: '500',
          color: 'rgba(33, 33, 33, 0.7)',
        }}
      >
        What our users are saying about us
      </h2>
      <Slider />
    </SectionContainer>
    <SectionContainer container style={{ paddingBottom: '140px' }} num={7}>
      <AsSeenOn />
    </SectionContainer>
  </div>
);

export default Index;
