/* eslint-disable max-len */
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { scroller, Element } from 'react-scroll';
import { whichCorp } from '../../utils/pageUtils';

const Index = () => {
  // const setLanguage = lang => {
  //   localStorage.setItem('selectedLanguage', lang);
  //   window.location.reload();
  // };
  const scrollToElement = element => {
    scroller.scrollTo(element, {
      duration: 1500,
      smooth: 'easeInOutQuart',
    });
  };
  const company = whichCorp();
  return (
    <div className="corporate-nav-page">
      <div className="section-1">
        <div className="en-fr">
          {/* {
            localStorage.getItem('selectedLanguage') === 'fr' ?
              <div>
                <span role="presentation" onClick={() => setLanguage('en')} style={{ fontWeight: 300 }}>
                  EN
                </span>
                <span>
                  FR
                </span>
              </div>
              :
              <div>
                <span>
                  EN
                </span>
                <span role="presentation" onClick={() => setLanguage('fr')} style={{ fontWeight: 300 }}>
                  FR
                </span>
              </div>
            } */}
        </div>
        <div className="logos">
          <img src={company.logo} alt={`${company.title} logo`} />
        </div>
        <FormattedHTMLMessage tagName="h1" id="corporateNavPage.title" defaultMessage={company.title} />
        <FormattedHTMLMessage tagName="h2" id="corporateNavPage.subTitle" defaultMessage={company.subTitle} />
        <div className="divider" />
        <FormattedHTMLMessage tagName="h4" id="pch.yourProviders" />
        <div className="corporate-nav-page-company-container">
          {
            company.providers.map(provider => (
              <div
                style={{ maxWidth: provider.maxWidth, width: provider.width }}
                onClick={() => scrollToElement(`${provider.path}`)}
                role="button"
                tabIndex={0}
                onKeyPress={() => scrollToElement(`${provider.path}`)}
              >
                <img src={provider.logo} alt={`${provider.title} logo`} />
              </div>
              ))
          }
        </div>
      </div>
      <div className="section-2">
        <div className="container">
          <div className="left">
            <Element name={company.provider_one.path}>
              <div className="bci-white-box">
                <img src={company.provider_one.logo} alt="Aspiria" />
              </div>
            </Element>
          </div>
          <div className="right">
            <FormattedHTMLMessage
              tagName="h2"
              id="corporateNavPage.providerOneType"
              defaultMessage={company.provider_one.type}
            />
            <FormattedHTMLMessage
              tagName="h1"
              id="corporateNavPage.providerOneName"
              defaultMessage={company.provider_one.name}
            />
            <FormattedHTMLMessage
              tagName="p"
              id="corporateNavPage.providerOnePrimaryDescription"
              defaultMessage={company.provider_one.primaryDescription}
            />
            <div className="divider" />
            {
              company.provider_one.secondaryDescription &&
              <FormattedHTMLMessage
                tagName="p"
                id="corporateNavPage.providerOneSecondaryDescription"
                defaultMessage={company.provider_one.secondaryDescription}
              />
            }
            <div className="button-link">
              <a href={company.provider_one.buttonUrl}>
                {company.provider_one.buttonLabel}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section-3">
        <div className="container">
          <div className="left">
            <FormattedHTMLMessage
              tagName="h2"
              id="corporateNavPage.providerTwoType"
              defaultMessage={company.provider_two.type}
            />
            <FormattedHTMLMessage
              tagName="h1"
              id="corporateNavPage.providerTwoName"
              defaultMessage={company.provider_two.name}
            />
            <FormattedHTMLMessage
              tagName="p"
              id="corporateNavPage.providerTwoPrimaryDescription"
              defaultMessage={company.provider_two.primaryDescription}
            />
            <div className="divider" />
            {
              company.provider_two.secondaryDescription &&
              <FormattedHTMLMessage
                tagName="p"
                id="corporateNavPage.providerTwoSecondaryDescription"
                defaultMessage={company.provider_two.secondaryDescription}
              />
            }
            <div className="button-link">
              <a href={company.provider_two.buttonUrl}>
                {company.provider_two.buttonLabel}
              </a>
            </div>
          </div>
          <div className="right">
            <Element name={company.provider_two.path}>
              <div className="bci-white-box">
                <img src={company.provider_two.logo} alt="People Connect" />
              </div>
            </Element>
          </div>
        </div>
      </div>
      <div className="section-4">
        <div className="container">
          <div className="left">
            <Element name={company.provider_three.path}>
              <div className="bci-white-box">
                <img src={company.provider_three.logo} alt="Maple" />
              </div>
            </Element>
          </div>
          <div className="right">
            <FormattedHTMLMessage
              tagName="h2"
              id="corporateNavPage.providerThreeType"
              defaultMessage={company.provider_three.type}
            />
            <FormattedHTMLMessage
              tagName="h1"
              id="corporateNavPage.providerThreeName"
              defaultMessage={company.provider_three.name}
            />
            <FormattedHTMLMessage
              tagName="p"
              id="corporateNavPage.providerThreePrimaryDescription"
              defaultMessage={company.provider_three.primaryDescription}
            />
            <div className="divider" />
            {
              company.provider_three.secondaryDescription &&
              <FormattedHTMLMessage
                tagName="p"
                id="corporateNavPage.providerThreeSecondaryDescription"
                defaultMessage={company.provider_three.secondaryDescription}
              />
            }
            <div className="button-link">
              <a href={company.provider_three.buttonUrl}>
                {company.provider_three.buttonLabel}
              </a>
            </div>
          </div>
        </div>
      </div>
      {
        company.provider_four &&
        <div className="section-5">
          <div className="container">
            <div className="left">
              <FormattedHTMLMessage
                tagName="h2"
                id="corporateNavPage.providerFourType"
                defaultMessage={company.provider_four.type}
              />
              <FormattedHTMLMessage
                tagName="h1"
                id="corporateNavPage.providerFourName"
                defaultMessage={company.provider_four.name}
              />
              <FormattedHTMLMessage
                tagName="p"
                id="corporateNavPage.providerFourPrimaryDescription"
                defaultMessage={company.provider_four.primaryDescription}
              />
              <div className="divider" />
              {
                company.provider_four.secondaryDescription &&
                <FormattedHTMLMessage
                  tagName="p"
                  id="corporateNavPage.providerFourSecondaryDescription"
                  defaultMessage={company.provider_four.secondaryDescription}
                />
              }
              <div className="button-link">
                <a href={company.provider_four.buttonUrl}>
                  {company.provider_four.buttonLabel}
                </a>
              </div>
            </div>
            <div className="right">
              <Element name={company.provider_four.path}>
                <div className="bci-white-box">
                  <img src={company.provider_four.logo} alt="People Connect" />
                </div>
              </Element>
            </div>
          </div>
        </div>
      }
      {
        company.provider_five &&
        <div className="section-2">
          <div className="container">
            <div className="left">
              <Element name={company.provider_five.path}>
                <div className="bci-white-box">
                  <img src={company.provider_five.logo} alt="Aspiria" />
                </div>
              </Element>
            </div>
            <div className="right">
              <FormattedHTMLMessage
                tagName="h2"
                id="corporateNavPage.providerFiveType"
                defaultMessage={company.provider_five.type}
              />
              <FormattedHTMLMessage
                tagName="h1"
                id="corporateNavPage.providerFiveName"
                defaultMessage={company.provider_five.name}
              />
              <FormattedHTMLMessage
                tagName="p"
                id="corporateNavPage.providerFivePrimaryDescription"
                defaultMessage={company.provider_five.primaryDescription}
              />
              <div className="divider" />
              {
                company.provider_five.secondaryDescription &&
                <FormattedHTMLMessage
                  tagName="p"
                  id="corporateNavPage.providerFiveSecondaryDescription"
                  defaultMessage={company.provider_five.secondaryDescription}
                />
              }
              <div className="button-link">
                <a href={company.provider_five.buttonUrl}>
                  {company.provider_five.buttonLabel}
                </a>
              </div>
            </div>
          </div>
        </div>
      }
      {
        company.provider_six &&
        <div className="section-3">
          <div className="container">
            <div className="left">
              <FormattedHTMLMessage
                tagName="h2"
                id="corporateNavPage.providerSixType"
                defaultMessage={company.provider_six.type}
              />
              <FormattedHTMLMessage
                tagName="h1"
                id="corporateNavPage.providerSixName"
                defaultMessage={company.provider_six.name}
              />
              <FormattedHTMLMessage
                tagName="p"
                id="corporateNavPage.providerSixPrimaryDescription"
                defaultMessage={company.provider_six.primaryDescription}
              />
              <div className="divider" />
              {
                company.provider_six.secondaryDescription &&
                <FormattedHTMLMessage
                  tagName="p"
                  id="corporateNavPage.providerSixSecondaryDescription"
                  defaultMessage={company.provider_six.secondaryDescription}
                />
              }
              <div className="button-link">
                <a href={company.provider_six.buttonUrl}>
                  {company.provider_six.buttonLabel}
                </a>
              </div>
            </div>
            <div className="right">
              <Element name={company.provider_six.path}>
                <div className="bci-white-box">
                  <img src={company.provider_six.logo} alt="People Connect" />
                </div>
              </Element>
            </div>
          </div>
        </div>
      }
      {
        company.provider_seven &&
        <div className="section-4">
          <div className="container">
            <div className="left">
              <Element name={company.provider_seven.path}>
                <div className="bci-white-box">
                  <img src={company.provider_seven.logo} alt="Maple" />
                </div>
              </Element>
            </div>
            <div className="right">
              <FormattedHTMLMessage
                tagName="h2"
                id="corporateNavPage.providerSevenType"
                defaultMessage={company.provider_seven.type}
              />
              <FormattedHTMLMessage
                tagName="h1"
                id="corporateNavPage.providerSevenName"
                defaultMessage={company.provider_seven.name}
              />
              <FormattedHTMLMessage
                tagName="p"
                id="corporateNavPage.providerSevenPrimaryDescription"
                defaultMessage={company.provider_seven.primaryDescription}
              />
              <div className="divider" />
              {
                company.provider_seven.secondaryDescription &&
                <FormattedHTMLMessage
                  tagName="p"
                  id="corporateNavPage.providerSevenSecondaryDescription"
                  defaultMessage={company.provider_seven.secondaryDescription}
                />
              }
              <div className="button-link">
                <a href={company.provider_seven.buttonUrl}>
                  {company.provider_seven.buttonLabel}
                </a>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Index;
