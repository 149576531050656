import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

class Section3a extends Component {
  constructor(props) {
    super(props);
    this.state = { showChart: false };
  }
  toggleChart = () => {
    this.setState(prevState => ({ showChart: !prevState.showChart }));
  }
  render() {
    const { showChart } = this.state;
    return (
      <div className="section-3a">
        <div className="header">
          <FormattedHTMLMessage
            tagName="h2"
            id="mlf.viewComparison"
            defaultMessage="View a comparison chart of Inkblot vs Beacon"
          />
          <button onClick={this.toggleChart}>
            {
              showChart ?
                <FormattedHTMLMessage
                  tagName="span"
                  id="mlf.hideChart"
                  defaultMessage="HIDE CHART"
                />
              :
                <FormattedHTMLMessage
                  tagName="span"
                  id="mlf.viewChart"
                  defaultMessage="VIEW CHART"
                />
            }
          </button>
        </div>
        {
          showChart &&
          <div className="chart">
            <div className="parent">
              <div />
              <FormattedHTMLMessage
                tagName="h4"
                id="mlf.offers"
                defaultMessage="OFFERS"
              />
              <FormattedHTMLMessage
                tagName="h4"
                id="mlf.idealFor"
                defaultMessage="IDEAL FOR"
              />
              <FormattedHTMLMessage
                tagName="h4"
                id="mlf.coverage"
                defaultMessage="COVERAGE"
              />
              <h3>Inkblot</h3>
              <FormattedHTMLMessage
                tagName="p"
                id="mlf.accessibleVideo"
                defaultMessage="Accessible video counselling on a secure and encrypted platform for use on any device (desktop, laptop, tablet, phone) by an accredited therapist"
              />
              <FormattedHTMLMessage
                tagName="p"
                id="mlf.anyPersonal"
                defaultMessage="Any personal, family or work-related stressor or mental health concern, including depression, anxiety, post traumatic stress, panic, insomnia. Inkblot also provides addiction treatment"
              />
              <FormattedHTMLMessage
                tagName="p"
                id="mlf.appointmentsAre"
                defaultMessage="MLF will cover the cost of next 10 hours of counselling for employees and their family members.  Appointments are booked directly on-line."
              />
              <h3>Beacon</h3>
              <FormattedHTMLMessage
                tagName="p"
                id="mlf.twelveWeek"
                defaultMessage="12-week course of digital therapy that’s guided and continuously personalized one-to-one by an accredited therapist"
              />
              <FormattedHTMLMessage
                tagName="p"
                id="mlf.mlfWill"
                defaultMessage="MLF will cover the cost for all 12 weeks of guided digital therapy for MLF employees and family members"
              />
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Section3a;
