import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';

import { SERVER_URL, THERAPY_URL } from '../../utils/environment';
import {
  unauthAuditProgress,
  auditProgress,
  whichCompany,
} from '../../utils/pageUtils';
import {
  logEvent,
  COMPLETED_SIGNUP,
} from '../../utils/amplitude';

class EsgSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      registerMode: false,
      sentReset: false,
      message: false,
    };
  }

  componentDidMount() {
    unauthAuditProgress(whichCompany().path, 'open_forgot_password');
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      sentReset: false,
      message: '',
    });
  };

  forgotPassword = () => {
    const values = {
      email: this.state.email,
      domain: whichCompany().email_domain,
    };
    axios
      .post(`${SERVER_URL}/api/v2/forgot_passwords/domain_only`, values)
      .then(response => {
        if (response.data.message) {
          if (response.data.message === 'redirect_to_sign_up') {
            this.setState({ message: false });
          } else {
            this.setState({ message: response.data.message });
          }
        }
      })
      .catch(error => {
        if (error.response && error.response.data.error) {
          this.setState({ message: error.response.data.error });
        } else {
          this.setState({ message: 'Error!' });
        }
      });
  };

  signUp = () => {
    const corporate_account = whichCompany().path;
    axios
      .post(`${SERVER_URL}/api/signup`, { ...this.state, corporate_account })
      .then(response => {
        const token = response.data.access_token;
        logEvent(COMPLETED_SIGNUP, { page: corporate_account });
        auditProgress(corporate_account, 'completed_signup', token);
        window.location.replace(`${THERAPY_URL}/redirect/${token}`);
      })
      .catch(error => {
        if (error.response && error.response.data.error) {
          this.setState({ message: error.response.data.error });
        } else {
          this.setState({ message: 'Error!' });
        }
      });
  };

  handleSubmitForCheck = event => {
    event.preventDefault();
    this.forgotPassword();
  };

  handleSubmitForRegister = event => {
    event.preventDefault();
    this.signUp();
  };

  render() {
    const { message } = this.state;
    const { dependants, path } = whichCompany();
    return (
      <div className="custom-auth custom-dependants">
        <div className="dependants-container custom-auth-container">
          <div className="dependant-auth-form">
            <h2>
              {this.props.intl.formatMessage({
                id: 'eap.signup',
                defaultMessage: 'Sign up',
              })}
            </h2>
            <form onSubmit={this.handleSubmitForRegister}>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.firstName',
                    defaultMessage: 'First Name',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="first_name"
                  placeholder="John"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.lastName',
                    defaultMessage: 'Last Name',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="last_name"
                  placeholder="Doe"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.email',
                    defaultMessage: 'Email',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  onBlur={this.handleSubmitForCheck}
                  required
                  type="text"
                  name="email"
                  value={this.state.email}
                  className="full-width"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.password',
                    defaultMessage: 'Password',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="password"
                  name="password"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.confirmPassword',
                    defaultMessage: 'Retype Password',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="password"
                  name="password"
                />
              </div>
              <div>
                <button type="submit" disabled={message} className={message ? 'disabled-button' : ''}>
                  {this.props.intl.formatMessage({
                    id: 'eap.signup',
                    defaultMessage: 'Sign Up',
                  })}
                </button>
              </div>
              <div style={{ marginTop: 20, color: 'red' }}>
                <p>{message}</p>
              </div>
              {dependants &&
              <h4>
                {this.props.intl.formatMessage({
                id: 'eap.spouseOrDependant',
                defaultMessage: 'Are you a spouse or a dependant?',
              })}
                {' '}
                <Link to={`/dependants?eap=${path}`} style={{ color: '#2a5ccd' }}>
                  {this.props.intl.formatMessage({
                  id: 'eap.registerHere',
                  defaultMessage: 'Sign Up Here',
                })}
                </Link>
              </h4>
              }
              <h4 style={{ marginTop: dependants ? 0 : '30px' }}>
                {this.props.intl.formatMessage({
                  id: 'eap.alreadyHave',
                  defaultMessage: 'Already have an account?',
                })}
                <Link to={`/sign_in?eap=${path}`} style={{ color: '#2a5ccd' }}>
                  {this.props.intl.formatMessage({
                    id: 'eap.signin',
                    defaultMessage: 'Sign In',
                  })}
                </Link>
              </h4>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

EsgSignUp.propTypes = {
  intl: PropTypes.object,
};

EsgSignUp.defaultProps = {
  intl: {},
};

export default injectIntl(EsgSignUp);
