import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';

import SessionTwo from '../../images/custom/illustrations/session-2.svg';
import { SERVER_URL } from '../../utils/environment';
import {
  unauthAuditProgress,
  whichCompany,
} from '../../utils/pageUtils';
import LogEventOnMount from '../LogEventOnMount';
import {
  OPEN_FORGOT_PASSWORD,
  logEvent,
  SENT_FORGOT_PASSWORD,
} from '../../utils/amplitude';

class FullyGatedSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      sentReset: false,
      message: false,
    };
  }

  componentDidMount() {
    unauthAuditProgress(whichCompany().path, 'open_forgot_password');
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      sentReset: false,
      message: false,
    });
  };

  forgotPassword = () => {
    const values = {
      email: this.state.email,
      domain: 'domain.com',
      company: whichCompany().path,
    };
    axios
      .post(`${SERVER_URL}/api/v2/forgot_passwords/full`, values)
      .then(response => {
        this.setState({ sentReset: true });
        logEvent(SENT_FORGOT_PASSWORD, { page: whichCompany().path });
        unauthAuditProgress(whichCompany().path, 'sent_forgot_password');

        if (response.data.message) {
          this.setState({ message: 'Not a valid work email address.' });
        }
      })
      .catch(() => {
        this.setState({ sentReset: true });
      });
  };

  handleSubmitForCheck = event => {
    event.preventDefault();
    this.forgotPassword();
  };

  render() {
    const { sentReset, message } = this.state;
    const { dependants, path, noEmailsSent } = whichCompany();
    return (
      <div className="custom-auth">
        <div className="auth-container custom-auth-container">
          <div className="custom-auth-text">
            <h2>
              {this.props.intl.formatMessage({
                id: 'eap.gettingStarted',
                defaultMessage: 'Getting Started',
              })}
            </h2>
            {!noEmailsSent &&
              <div>
                <p>
                  {this.props.intl.formatMessage({
                    id: 'eap.mostLikelyReceived',
                    defaultMessage:
                      'You most likely have received an email from Inkblot sent to your work email address with a direct link to set up your account.',
                  })}
                </p>
                <p>
                  {this.props.intl.formatMessage({
                    id: 'eap.notFindEmailFully',
                    defaultMessage:
                      'If you cannot find the registration email, please enter your work email address below:',
                  })}
                </p>
              </div>
            }
            <form onSubmit={this.handleSubmitForCheck}>
              <h4>
                {this.props.intl.formatMessage({
                  id: 'eap.workEmail',
                  defaultMessage: 'Work Email *',
                })}
              </h4>
              <input name="email" type="text" onChange={this.handleChange} />
              <button disabled={sentReset} type="submit">
                {this.props.intl.formatMessage({
                  id: 'eap.submit',
                  defaultMessage: 'Submit',
                })}
              </button>
            </form>
            {sentReset && (
              <p>
                {message ||
                  'We will send password reset instructions to you shortly.'}
              </p>
            )}
            {dependants && (
              <h4>
                {this.props.intl.formatMessage({
                  id: 'eap.spouseOrDependant',
                  defaultMessage: 'Are you a spouse or a dependant?',
                })}{' '}
                <Link
                  to={`/dependants?eap=${path}`}
                  style={{ color: '#2a5ccd' }}
                >
                  {this.props.intl.formatMessage({
                      id: 'eap.registerHere',
                      defaultMessage: 'Sign Up Here',
                    })}
                </Link>
              </h4>
            )}
            <h4>
              {this.props.intl.formatMessage({
                id: 'eap.alreadyHave',
                defaultMessage: 'Already have an account?',
              })}
              <Link to={`/sign_in?eap=${path}`} style={{ color: '#2a5ccd' }}>
                {this.props.intl.formatMessage({
                  id: 'eap.signin',
                  defaultMessage: 'Sign In',
                })}
              </Link>
            </h4>
          </div>
          <img src={SessionTwo} alt="session two" />
        </div>
        <LogEventOnMount
          eventType={OPEN_FORGOT_PASSWORD}
          eventProperties={{ page: path }}
        />
      </div>
    );
  }
}

FullyGatedSignUp.propTypes = {
  intl: PropTypes.object,
};

FullyGatedSignUp.defaultProps = {
  intl: {},
};

export default injectIntl(FullyGatedSignUp);
