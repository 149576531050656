import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { whichCompany } from '../../utils/pageUtils';
import SectionContainer from '../../components/SectionContainer';
import teamworkWhite12 from '../../images/custom/illustrations/teamwork-white-12.svg';
import crisisLine from '../../images/custom/illustrations/crisis-line.svg';

const SectionOne = props => {
  const { EFAP, intl, EAP } = props;
  const company = whichCompany();
  const crisisYn = company.crisisText;
  return (
    <SectionContainer container num={1}>
      <div className="row">
        <div className="col-6">
          <div className="header">
            <div className={EFAP ? 'EFAP-hidden' : ''}>
              <p className="custom-header">
                <span className="dark-blue">
                  {
                    whichCompany().path === 'eastcoaststrong' ?
                    intl.formatMessage({
                      id: 'eap.sincereCondolences',
                      defaultMessage: 'Our sincere condolences to all of those affected by the recent tragedy in Nova Scotia.',
                    })
                    :
                    intl.formatMessage({
                      id: 'eap.yourMentalHealth',
                      defaultMessage: 'Your mental health is as important as your physical health.',
                    })
                  }
                </span>{' '}
                {
                  whichCompany().path === 'eastcoaststrong' ?
                  intl.formatMessage({
                    id: 'eap.weStandBy',
                    defaultMessage: 'We stand by you in this extremely difficult time.',
                  })
                  :
                  intl.formatMessage({
                    id: 'eap.takeCareWith',
                    defaultMessage: 'Take care of it with Inkblot’s confidential video counselling.',
                  })
                }
              </p>
            </div>
            <div className={EFAP ? '' : 'EFAP-hidden'}>
              <p className="custom-header">
                <span className="dark-blue">
                  {intl.formatMessage({
                    id: 'eap.yourWellBeing',
                    defaultMessage: 'Your well-being matters.',
                  })}
                </span>{' '}
              </p>
              <p className="custom-header">
                {
                  EAP ?
                  intl.formatMessage({
                    id: 'eap.takeCareEap',
                    defaultMessage:
                      'Take care of it with your confidential Employee Assistance Program (EAP).',
                  })
                  :
                  intl.formatMessage({
                    id: 'eap.takeCare',
                    defaultMessage:
                      'Take care of it with your confidential Employee and Family Assistance Program (EFAP).',
                  })
                }
              </p>
            </div>
          </div>
        </div>
        <div className="col-6 custom-svg" style={{ display: 'flex', flexDirection: 'column' }}>
          <img src={teamworkWhite12} alt="" />
          {
            crisisYn &&
            <div className="crisis-line">
              <img src={crisisLine} alt="Orange alert" />
              <FormattedHTMLMessage tagName="span" defaultMessage="If you are in crisis, contact: <b>1-855-933-0103</b> (toll-free, 24/7)" id="eap.crisisText" />
            </div>
          }
        </div>
      </div>
    </SectionContainer>
  );
};

SectionOne.propTypes = {
  EFAP: PropTypes.bool,
  intl: PropTypes.object,
  EAP: PropTypes.bool,
};

SectionOne.defaultProps = {
  intl: {},
  EFAP: false,
  EAP: false,
};

export default injectIntl(SectionOne);
