/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import queryString from 'query-string';
import _ from 'lodash';

import InkblotTherapy from './InkblotTherapy';
import InkblotAssess from './InkblotAssess';
import PasswordReset from './PasswordReset';
import NotFound from './NotFound';
import Spinner from '../SpinnerButton/spinner.svg';
import {
  THERAPY_URL,
  ASSESS_URL,
  SERVER_URL,
  MARKETING_URL,
} from '../../utils/environment';
import Layout from '../layout';
import {
  COMPLETE_CREATE_PASSWORD,
  logEvent,
  setUserId,
  START_ONBOARDING,
} from '../../utils/amplitude';
import { validateBirthday } from '../../utils/form';

class EligibilityWelcome extends React.Component {
  constructor(props) {
    super(props);
    // const params = queryString.parse(window.location.search);
    this.state = {
      page: 0,
      values: {
        password: null,
        password_confirmation: null,
        preferred_name: '',
        locale: 'en',
        email: null,
        timezone: null,
        gender: null,
        custom: '',
        dob: '',
      },
      error: {},
      loading: false,
      userId: null,
      emailProvided: true,
    };
  }

  componentWillMount = () => {
    const params = queryString.parse(window.location.search);
    const { eligibility_token } = this.props;

    localStorage.setItem(
      'selectedLanguage',
      params.locale ? params.locale : 'en',
    );
    this.setState({
      loading: true,
      values: {
        ...this.state.values,
        locale: localStorage.getItem('selectedLanguage'),
      },
    });

    axios
      .get(`${SERVER_URL}/api/v2/eligibilities/${eligibility_token}`)
      .then(resp => {
        const {
          data: { password_change_yn, student_yn, id, auto_generated_email_yn },
        } = resp;

        if (auto_generated_email_yn) {
          this.setState({ emailProvided: false });
        }
        // Redirect the user if they've already created their password
        if (!password_change_yn) {
          if (params.source === 'assess') {
            if (student_yn) {
              window.location.replace(`${ASSESS_URL}/students/?signin=true`);
            } else {
              window.location.replace(`${ASSESS_URL}?signin=true`);
            }
          } else {
            window.location.replace(`${MARKETING_URL}/sign_in`);
          }
        }

        setUserId(id);
        logEvent(START_ONBOARDING, { page: params.source });
        this.setState({ loading: false, page: 1, userId: id });
      })
      .catch(() => {
        this.setState({ loading: false, page: -1 });
      });
  };

  setLanguage = lang => {
    const params = queryString.parse(window.location.search);
    localStorage.setItem('selectedLanguage', lang);
    this.setState({
      values: { locale: localStorage.getItem('selectedLanguage') },
    });
    params.locale = lang;
    const stringified = queryString.stringify(params);
    window.location.replace(
      `${window.location.origin}${window.location.pathname}?${stringified}`,
    );
  };

  handleNext = () => {
    this.setState({ page: this.state.page + 1 });
  };

  handlePrevious = () => {
    this.setState({ page: this.state.page - 1 });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ loading: true });

    const params = queryString.parse(window.location.search);
    const { eligibility_token } = this.props;
    const { values } = this.state;
    let formattedValues = {
      ...values,
      gender: values.gender === 'custom' ? values.custom : values.gender,
    };

    if (!values.email) {
      formattedValues = _.omit(formattedValues, ['email']);
    }

    axios
      .put(
        `${SERVER_URL}/api/v2/eligibilities/${eligibility_token}`,
        formattedValues,
      )
      .then(resp => {
        const {
          data: { access_token, user_id },
        } = resp;

        setUserId(user_id);
        logEvent(COMPLETE_CREATE_PASSWORD, { page: params.source });
        this.setState({ loading: false });

        if (params.source !== 'assess') {
          window.location.replace(
            `${THERAPY_URL}/redirect/${access_token}?log_amp=true`,
          );
        } else if (params.source === 'assess') {
          window.location.replace(
            `${ASSESS_URL}/redirect/${access_token}?log_amp=true`,
          );
        }
      })
      .catch(() => {
        const isFr = localStorage.getItem('selectedLanguage') === 'fr';
        this.setState({
          loading: false,
          error: {
            server: isFr
              ? "Une erreur inconnue s'est produite. Veuillez contacter le Soutien à la clientèle."
              : 'An unknown error has occurred. Please contact customer support.',
          },
        });
      });
  };

  validateForm = () => {
    const isFr = localStorage.getItem('selectedLanguage') === 'fr';
    const requiredField = isFr
      ? 'Ceci est un champ obligatoire'
      : 'This is a required field';
    const passwordSix = isFr
      ? 'Le mot de passe doit être au moins de 6 caractères '
      : 'Password must be at least 6 characters';
    const passwordMatch = isFr
      ? 'Les mots de passe doivent correspondre'
      : 'Passwords must match';

    const {
      values: {
        password,
        password_confirmation,
        timezone,
        gender,
        custom,
        dob,
      },
    } = this.state;
    const error = {};

    if (password === '') {
      error.password = requiredField;
    }

    if (password_confirmation === '') {
      error.password_confirmation = requiredField;
    }

    if (password) {
      if (password.length < 6) {
        error.password = passwordSix;
      } else if (password !== password_confirmation) {
        error.password_confirmation = passwordMatch;
      }
    }

    if (timezone === '') {
      error.timezone = requiredField;
    }

    if (dob === '') {
      error.dob = requiredField;
    }

    if (dob && validateBirthday(dob)) {
      error.dob = validateBirthday(dob);
    }

    if (gender === '') {
      error.gender = requiredField;
    }

    if (gender && gender === 'custom' && custom === '') {
      error.custom = requiredField;
    }

    this.setState({ error });
  };

  render() {
    const params = queryString.parse(window.location.search);
    const { page, values, error, loading, userId, emailProvided } = this.state;
    const { eligibility_token, location } = this.props;

    if (loading) {
      return <img src={Spinner} alt="loading" />;
    }

    return (
      <Layout location={location}>
        <div className="onboarding-en-fr">
          {localStorage.getItem('selectedLanguage') === 'en' ? (
            <div>
              <span style={{ fontWeight: 600 }}>EN </span>
              <span
                role="presentation"
                onClick={() => this.setLanguage('fr')}
                style={{ fontWeight: 300 }}
              >
                FR
              </span>
            </div>
          ) : (
            <div>
              <span
                role="presentation"
                onClick={() => this.setLanguage('en')}
                style={{ fontWeight: 300 }}
              >
                EN{' '}
              </span>
              <span style={{ fontWeight: 600 }}>FR</span>
            </div>
          )}
        </div>
        {page === 1 && params.source !== 'assess' && (
          <InkblotTherapy handleNext={this.handleNext} />
        )}
        {page === 1 && params.source === 'assess' && (
          <InkblotAssess handleNext={this.handleNext} />
        )}
        {page === 2 && (
          <PasswordReset
            token={eligibility_token}
            handleSubmit={this.handleSubmit}
            handlePrevious={this.handlePrevious}
            loading={loading}
            values={values}
            error={error}
            validateForm={this.validateForm}
            source={params.source}
            userId={userId}
            emailProvided={emailProvided}
          />
        )}
        {page === -1 && <NotFound />}
      </Layout>
    );
  }
}

EligibilityWelcome.propTypes = {
  location: PropTypes.object,
  eligibility_token: PropTypes.string,
};

EligibilityWelcome.defaultProps = {
  location: null,
  eligibility_token: '',
};

export default EligibilityWelcome;
