import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import LanguageSelector from './LanguageSelector';
import PeopleLogo from '../../images/people-connect-total-health/people-logo.png';

import PeopleLogoTwo from '../../images/people-connect-total-health/people-logo-two.png';
import TelusAkira from '../../images/people-connect-total-health/telus-akira.png';
import PocketPills from '../../images/people-connect-total-health/pocket-pills.png';

const Index = () => (
  <div className="people-connect-health">
    <LanguageSelector />
    <div className="section-1">
      <img className="people-logo" src={PeopleLogo} alt="people Logo" />
      <FormattedHTMLMessage
        tagName="h1"
        id="pch.peopleConnectTotalHealth"
        defaultMessage="People Connect Total Health"
      />
      <div className="divider" />
      <div className="a-unique">
        <FormattedHTMLMessage tagName="p" id="pch.aUnique" />
      </div>
      <FormattedHTMLMessage tagName="h4" id="pch.yourProviders" />
      <div className="company-container">
        <div>
          <img src={PeopleLogoTwo} alt="people logo two" />
        </div>
        <div>
          <img src={TelusAkira} alt="telus akira" />
        </div>
        <div>
          <img src={PocketPills} alt="PocketPills" />
        </div>
      </div>
    </div>
    <div className="section-2">
      <div className="container">
        <div className="left">
          <div className="white-box">
            <img src={PeopleLogoTwo} alt="people" />
          </div>
        </div>
        <div className="right">
          <FormattedHTMLMessage
            tagName="h2"
            id="pch.mentalHealthSolution"
            defaultMessage="Mental Health Solution"
          />
          <FormattedHTMLMessage
            tagName="h1"
            id="pch.peopleConnect"
            defaultMessage="People Connect"
          />
          <FormattedHTMLMessage
            tagName="p"
            id="pch.proprietary"
            defaultMessage="People Connect, our proprietary mental health solution, was built to positively impact the mental health of your employees and support them through trying times. This service provides employees with educational resources and access to immediate care through virtual counselling. We include a full diagnostic tool for employees to better understand their mental health – and, we’re the only provider that includes consultation with a psychiatrist in a virtual collaboration meeting.<br><br>People Connect utilizes the coverage provided under your group benefits plan paramedical coverage making it easy to get professional and affordable therapy. There is no charge for the insured’s first appointment. Plan members can claim the costs of additional sessions under the paramedical coverage of their group benefits plan."
          />
          <a
            className="pch-button"
            href="https://pcpeopleconnect.com/"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedHTMLMessage
              tagName="a"
              id="pch.launchPeopleConnect"
              defaultMessage="Launch People Connect"
            />
          </a>
        </div>
      </div>
    </div>
    <div className="section-3">
      <div className="container">
        <div className="left">
          <div className="white-box">
            <img src={TelusAkira} alt="akira" />
          </div>
        </div>
        <div className="right">
          <FormattedHTMLMessage
            tagName="h2"
            id="pch.nursePractitioners"
            defaultMessage="Nurse practitioners"
          />
          <FormattedHTMLMessage
            tagName="h1"
            id="pch.akira"
            defaultMessage="Akira"
          />
          <FormattedHTMLMessage
            tagName="p"
            id="pch.partneredAkira"
            defaultMessage="People Corporation has partnered with Akira offered by TELUS Health to offer special pricing for our clients. Akira is an app-based service, that connects plan members and their families to nurses and nurse practitioners. Service is available 24/7 and health professionals can: answer medical questions, diagnose, suggest treatment, renew prescriptions, and give referrals. Purchased through People Corporation, this service includes People Connect, our enhanced mental health solution that provides plan members with access to therapists, diagnostic tools, and psychiatric collaboration.<br><br>Contact your People Corporation consultant for more information, or email healthsolutions@peoplecorporation.com"
          />
          <a
            className="pch-button"
            href="http://plus.telushealth.co/page/employers/akira/"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedHTMLMessage
              tagName="a"
              id="pch.visitWebsite"
              defaultMessage="Visit Website"
            />
          </a>
        </div>
      </div>
    </div>
    <div className="section-4">
      <div className="container">
        <div className="left">
          <div className="white-box">
            <img src={PocketPills} alt="PocketPills" />
          </div>
        </div>
        <div className="right">
          <FormattedHTMLMessage
            tagName="h2"
            id="pch.pharmacy"
            defaultMessage="Pharmacy"
          />
          <FormattedHTMLMessage
            tagName="h1"
            id="pch.pocketPills"
            defaultMessage="PocketPills"
          />
          <FormattedHTMLMessage
            tagName="p"
            id="pch.saveonDrug"
            defaultMessage="<b>1. Save -15% on Drug Costs</b><br/>• Low dispensing fee of $7.00, low markup below provincial rates<br/><br/><b>2. Excellent Member Experience</b><br/>• 24/7 easy access to pharmacists via live chat, SMS, email, and phone<br/>• PocketPacks - easy to tear and take<br/>• Manage dependents, additional family and others<br/>• FREE delivery - maintenance + acute<br/><br/><b>3. Improve Employee Health </b><br/>• Learn about your drug and diagnosis<br/>• Improved adherence<br/><br/><b>4. Easy Enrollment</b><br/>• Auto-enrolled empoyees using census data<br/>"
          />
          <a
            className="pch-button"
            href="https://www.youtube.com/watch?v=F8nM85c4PdI&feature=youtu.be"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedHTMLMessage
              tagName="a"
              id="pch.viewDetails"
              defaultMessage="View Details"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default Index;
