import React from 'react';
import check from '../../images/_check.svg';
import nope from '../../images/_no.svg';
import inkblotLogo from '../../images/_logomark.svg';

const CompareTable = () => (
  <table style={{ marginTop: '80px' }} className="ink-compare-table">
    <thead>
      <tr>
        <th style={{ visibility: 'hidden' }} />
        <th>
          <img style={{ marginBottom: '0' }} src={inkblotLogo} alt="Logo" />
        </th>
        <th>Average Canadian In-Person Counselling</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Price</td>
        <td>$75</td>
        <td>$150-200</td>
      </tr>
      <tr>
        <td>Confidential</td>
        <td>
          <img src={check} alt="check" />
        </td>
        <td>
          <img src={check} alt="check" />
        </td>
      </tr>
      <tr>
        <td>Secure</td>
        <td>
          <img src={check} alt="check" />
        </td>
        <td>
          <img src={check} alt="check" />
        </td>
      </tr>
      <tr>
        <td>Wait-Times</td>
        <td>Less than two weeks</td>
        <td>4-6 weeks</td>
      </tr>
      <tr>
        <td>Evenings/Weekends</td>
        <td>
          <img src={check} alt="check" />
        </td>
        <td>
          <img src={nope} alt="no" />
        </td>
      </tr>
      <tr>
        <td>Location</td>
        <td>Anywhere</td>
        <td>Fixed/Office</td>
      </tr>
      <tr>
        <td>Focused Specialization</td>
        <td>
          <img src={check} alt="check" />
        </td>
        <td>
          <img src={nope} alt="no" />
        </td>
      </tr>
      <tr>
        <td>Multiple Languages</td>
        <td>
          <img src={check} alt="check" />
        </td>
        <td>
          <img src={nope} alt="no" />
        </td>
      </tr>
      <tr>
        <td>Quality Guarantee</td>
        <td>
          <img src={check} alt="check" />
        </td>
        <td>
          <img src={nope} alt="no" />
        </td>
      </tr>
      <tr>
        <td>First Session Free</td>
        <td>
          <img src={check} alt="check" />
        </td>
        <td>
          <img src={nope} alt="no" />
        </td>
      </tr>
      <tr>
        <td>Scheduling</td>
        <td>Automated</td>
        <td>Manual</td>
      </tr>
      <tr>
        <td>Group Sessions</td>
        <td>$20/hour</td>
        <td>
          <img src={nope} alt="no" />
        </td>
      </tr>
      <tr>
        <td>Cancellation Policy</td>
        <td>8 hours/half price</td>
        <td>24 hours/full price</td>
      </tr>
      <tr>
        <td>Match Type</td>
        <td>Computer based</td>
        <td>Random</td>
      </tr>
      <tr>
        <td>Support</td>
        <td>24 hours/day</td>
        <td>
          <img src={nope} alt="no" />
        </td>
      </tr>
    </tbody>
  </table>
);

export default CompareTable;
