import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import SessionTwo from '../../images/custom/illustrations/session-2.svg';
import { SERVER_URL } from '../../utils/environment';
import {
  logEvent,
  SENT_FORGOT_PASSWORD,
  OPEN_FORGOT_PASSWORD,
} from '../../utils/amplitude';
import { parseEapCodeFromPath, whichCompany } from '../../utils/pageUtils';
import LogEventOnMount from '../LogEventOnMount';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      sent_reset: false,
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  forgotPassword = () => {
    axios
      .post(`${SERVER_URL}/api/v2/forgot_passwords`, {
        email: this.state.email,
      })
      .then(() => {
        logEvent(SENT_FORGOT_PASSWORD, { page: parseEapCodeFromPath() });
        this.setState({ sent_reset: true });
      })
      .catch(() => {
        this.setState({ sent_reset: true });
      });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.forgotPassword();
  };

  render() {
    const { sent_reset } = this.state;
    const path = window.location.search.split('eap=')[1];
    const hasDependant = whichCompany().dependants;

    return (
      <div className="custom-auth">
        <div className="auth-container custom-auth-container">
          <div className="custom-auth-text">
            <h2>
              {this.props.intl.formatMessage({
                id: 'eap.gettingStarted',
                defaultMessage: 'Getting Started',
              })}
            </h2>
            <p>
              {this.props.intl.formatMessage({
                id: 'eap.shouldveSent',
                defaultMessage:
                  'You should have been sent an email from Inkblot with a direct link to set up your account.',
              })}
            </p>
            <p>
              {this.props.intl.formatMessage({
                id: 'eap.unableToFind',
                defaultMessage:
                  'If you’re unable to find the email, please use the field below to be sent an updated link to change your password.',
              })}
            </p>
            <form onSubmit={this.handleSubmit}>
              <h4>
                {this.props.intl.formatMessage({
                  id: 'eap.workEmail',
                  defaultMessage: 'Work Email *',
                })}
              </h4>
              <input name="email" type="text" onChange={this.handleChange} />
              <button disabled={sent_reset} type="submit">
                {this.props.intl.formatMessage({
                  id: 'eap.sendPassword',
                  defaultMessage: 'Send me a new password',
                })}
              </button>
            </form>
            <h4>
              {this.props.intl.formatMessage({
                id: 'eap.alreadyHave',
                defaultMessage: 'Already have an account?',
              })}
              <Link to={`/sign_in?eap=${path}`} style={{ color: '#2a5ccd' }}>
                {this.props.intl.formatMessage({
                  id: 'eap.signin',
                  defaultMessage: 'Sign In',
                })}
              </Link>
            </h4>
            {hasDependant && (
              <h4>
                {this.props.intl.formatMessage({
                  id: 'eap.spouseOrDependant',
                  defaultMessage: 'Are you a spouse or dependant?',
                })}{' '}
                <Link
                  to={`/dependants?eap=${path}`}
                  style={{ color: '#2a5ccd' }}
                >
                  {this.props.intl.formatMessage({
                    id: 'eap.registerHere',
                    defaultMessage: 'Sign Up Here',
                  })}
                </Link>
              </h4>
            )}
            {sent_reset && (
              <p>We will send password reset instructions to you shortly.</p>
            )}
          </div>
          <img src={SessionTwo} alt="session two" />
        </div>
        <LogEventOnMount
          eventType={OPEN_FORGOT_PASSWORD}
          eventProperties={{ page: parseEapCodeFromPath() }}
        />
      </div>
    );
  }
}

SignUp.propTypes = {
  intl: PropTypes.object,
};

SignUp.defaultProps = {
  intl: {},
};

export default injectIntl(SignUp);
