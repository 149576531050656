import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import beaconLogo from '../../images/custom/logos/beacon-logo.png';

const Section3 = () => (
  <div className="section section-3">
    <div className="image">
      <img src={beaconLogo} alt="Beacon logo" />
    </div>
    <div className="text">
      <FormattedHTMLMessage
        tagName="h3"
        id="mlf.guidedDigital"
        defaultMessage="GUIDED DIGITAL THERAPY"
      />
      <hr style={{ width: '276px', marginBottom: '20px', backgroundColor: '#18274b' }} />
      <h2>Beacon</h2>
      <FormattedHTMLMessage
        tagName="p"
        id="mlf.beaconDigital"
        defaultMessage="BEACON digital therapy is available anytime, anywhere you need it.
        Provides a complete 12-week course of therapy that’s guided and
        continuously personalized one-to-one by an accredited therapist."
      />
      <FormattedHTMLMessage
        tagName="p"
        id="mlf.mostSuitableBeacon"
        defaultMessage="<span class='second-p'>Most suitable to improve mild to moderate symptoms
        of depression, anxiety, posttraumatic stress injury (PTSI/PTSD) and insomnia.</span>"
      />
      <br />
      <FormattedHTMLMessage
        tagName="span"
        id="mlf.launchBeacon"
        defaultMessage="<a href='www.mindbeacon.com/bmaf459'>LAUNCH BEACON</a>"
      />
    </div>
  </div>
);

export default Section3;
