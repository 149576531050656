import React from 'react';
import _ from 'lodash';

import SectionContainer from '../../components/SectionContainer';
import myWellnessLaptop from '../../images/gallivan/assets/heroCounsellor.png';
import myWellnessGuy from '../../images/gallivan/assets/chairman.png';
import myWellnessLogo from '../../images/gallivan/_myWellnessLogo.png';
import MatchingBar from '../../images/gallivan/assets/matchingbar.png';
import MatchingBariPad from '../../images/gallivan/assets/matchBariPad.png';
import InkblotLogo from '../../images/_ink-logo.svg';
import {
  whichSchool,
  securityGall,
  unauthAuditProgress,
} from '../../utils/pageUtils';
import Check from '../../images/_check.svg';
import Match from '../../images/gallivan/assets/match.png';
import Survey from '../../images/gallivan/assets/survey.png';
import FeelBetter from '../../images/gallivan/assets/feelbetter.png';
import Schedule from '../../images/gallivan/assets/schedule.png';
import { logEvent, OPEN_SIGNUP } from '../../utils/amplitude';

const school = whichSchool();

const toggleSignUp = () => {
  localStorage.setItem('signUp', 'open');
  logEvent(OPEN_SIGNUP, school.key);
  unauthAuditProgress(school.key, 'open_signup');
  window.scrollTo({
    top: window.pageYOffset + 1,
    behavior: 'smooth',
  });
};

const Index = () => (
  <div className="gallivan-index-page">
    <SectionContainer container num={1}>
      <div className="row">
        <div className="col-6">
          <div className="header">
            <p style={{ marginBottom: '30px' }} className="gallivan-header">
              Everyone needs someone to talk to.
            </p>
            <p style={{ marginBottom: '35px' }} className="gallivan-header-2">
              Secure and confidential video counselling anytime, anywhere, with
              a practitioner matched to your needs.
            </p>
            <button
              title="Find a Counsellor"
              className="gallivan-button-teal"
              onClick={() => toggleSignUp()}
            >
              <span>Find a Counsellor</span>
            </button>
          </div>
        </div>
        <div className="col-6 my-wellness-laptop">
          <img src={myWellnessLaptop} alt="" />
        </div>
      </div>
    </SectionContainer>
    <SectionContainer container num={2}>
      <div className="logo-container">
        <div className="logo-block">
          <img src={school.logo} alt="key" />
        </div>
        <div className="logo-block">
          <img src={myWellnessLogo} alt="myWellness" />
        </div>
        <div className="logo-block">
          <img src={InkblotLogo} alt="inkblot" />
        </div>
      </div>
      <p className="gallivan-header-2">
        Mywellness and Inkblot have partnered to bring you an unmatched online
        counselling service.
      </p>
      <div className="matching-bar-container">
        <div className="green-cross-section">
          <img
            className="matching-bar-desktop"
            src={MatchingBar}
            alt="matching-bar"
          />
          <img
            className="matching-bar-ipad"
            src={MatchingBariPad}
            alt="matching-bar"
          />
        </div>
      </div>
      <div className="matching-bar-container-mobile">
        <div className="my-wellness-block-content">
          <img src={Survey} alt="Survey" />
          <p>Fill out our simple survey.</p>
        </div>
        <div className="my-wellness-block-content">
          <img src={Match} alt="Match" />
          <p>Choose a counsellor matched specifically to you.</p>
        </div>
        <div className="my-wellness-block-content">
          <img src={Schedule} alt="Schedule" />
          <p>
            Schedule your first session with no wait times. Evening and weekend
            appointments available.
          </p>
        </div>
        <div className="my-wellness-block-content">
          <img src={FeelBetter} alt="FeelBetter" />
          <p>
            Speak with your counsellor and <b>feel better.</b>
          </p>
        </div>
      </div>
      <div className="green-cross-section" />

      {/* <div className="my-wellness-block">
        <div className="my-wellness-block-section">
          <div className="my-wellness-block-content">
            <img src={Awake} alt="awake" />
            <p>Fill out our simple survey.</p>
          </div>
        </div>
        <div className="my-wellness-block-section">
          <div className="my-wellness-block-content">
            <img src={Awake} alt="awake" />
            <p>Choose a counsellor matched specifically to you.</p>
          </div>
        </div>
        <div className="my-wellness-block-section">
          <div className="my-wellness-block-content">
            <img src={Awake} alt="awake" />
            <p>
              Schedule your first session with no wait times. Evening and weekend appointments
              available.
            </p>
          </div>
        </div>
        <div className="my-wellness-block-section">
          <div className="my-wellness-block-content">
            <img src={Awake} alt="awake" />
            <p>
              Speak with your counsellor and <b>feel better.</b>
            </p>
          </div>
        </div>
      </div> */}
    </SectionContainer>
    <SectionContainer container num={3}>
      <div className="row">
        <div className="col-6">
          <img className="my-wellness-guy" src={myWellnessGuy} alt="" />
        </div>
        <div className="col-6">
          <div>
            <p style={{ marginBottom: '30px' }} className="gallivan-header">
              We&#39;ve got you covered
            </p>
            <p
              className="gallivan-benefits-text"
              dangerouslySetInnerHTML={school.benefitsText}
            />
            {school.key !== 'greatplains' && (
              <p
                style={{ marginBottom: '35px' }}
                className="gallivan-benefits-text"
              >
                All subsequent sessions are $37.50/thirty minutes.&nbsp;
                <span>
                  That’s roughly 50% less than the national average.&nbsp;
                </span>
                Plus, with Inkblot, you&#39;ll be able to keep seeing your
                counsellor after you graduate.
              </p>
            )}
            {school.key !== 'greatplains' && (
              <a
                title="Insurance Information"
                href={school.insuranceInformationUrl}
                className="gallivan-button-green-large"
              >
                <span>Insurance Information</span>
              </a>
            )}
          </div>
        </div>
      </div>
    </SectionContainer>
    <SectionContainer container num={4}>
      <div className="center">
        <h2>Your security is our top priority</h2>
        <div style={{ marginTop: '50px' }} className="security-box">
          {_.map(securityGall, (data, i) => (
            <p key={i} style={{ display: 'flex', alignItems: 'center' }}>
              <img style={{ marginRight: '34px' }} src={Check} alt="check" />
              {data}
            </p>
          ))}
        </div>
      </div>
    </SectionContainer>
  </div>
);

export default Index;
