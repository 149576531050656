import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import inkblotLogo from '../../images/custom/logos/inkblot-logo-mlf.png';

const Section2 = () => (
  <div className="section section-2">
    <div className="text">
      <FormattedHTMLMessage
        tagName="h3"
        id="mlf.talkTherapy"
        defaultMessage="TALK THERAPY"
      />
      <hr style={{ width: '276px', marginBottom: '20px', backgroundColor: '#18274b' }} />
      <h2>Inkblot</h2>
      <FormattedHTMLMessage
        tagName="p"
        id="mlf.inkblotIs"
        defaultMessage="Inkblot Therapy is Canada&apos;s top provider of online
        video-based one-on-one or couples counselling. Care is provided
        by highly experienced professionals across Canada. Inkblot highly
        values long-term relationships with care providers. Your first
        30-minute individual counselling session is complimentary, following which
        Maple Leaf Foods covers employees and eligible dependents for 10
        counselling hours each. Subsequent sessions are reimbursable
        through your paramedical health coverage."
      />
      <FormattedHTMLMessage
        tagName="p"
        id="mlf.mostSuitableInkblot"
        defaultMessage="<span class='second-p'>Most suitable for those seeking online counselling and a longer-term relationship with their counsellor.</span>"
      />
      <br />
      <FormattedHTMLMessage
        tagName="span"
        id="mlf.launchInkblot"
        defaultMessage="<a href='https://inkblottherapy.com/mapleleaffoods'>LAUNCH INKBLOT</a>"
      />
    </div>
    <div className="image">
      <img src={inkblotLogo} alt="Inkblot logo" />
    </div>
  </div>
);

export default Section2;
