import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import morneauLogo from '../../images/custom/logos/morneau-logo.png';

const Section4 = () => (
  <div className="section section-4">
    <div className="text">
      <h3>EFAP</h3>
      <hr style={{ width: '276px', marginBottom: '20px', backgroundColor: '#ffffff' }} />
      <h2>Morneau Shepell</h2>
      <FormattedHTMLMessage
        tagName="p"
        id="mlf.yourEmployee"
        defaultMessage="Your Employee and Family Assistance Program (EFAP) provides you
        with immediate and confidential help for any work, health or life
        concern. We’re available anytime and anywhere. Let us help: 1.844.880.9142"
      />
      <br />
      <FormattedHTMLMessage
        tagName="span"
        id="mlf.visitWebsite"
        defaultMessage="<a href='www.workhealthlife.com'>VISIT WEBSITE</a>"
      />
    </div>
    <div className="image">
      <img src={morneauLogo} alt="Morneau Shepell logo" />
    </div>
  </div>
);

export default Section4;
