import React from 'react';
import { Link } from 'gatsby';
import { COUNTRY } from '../../utils/environment';

export const faqData = [
  {
    id: 1,
    question: 'Why Inkblot?',
    answer: (
      <div>
        <p>
          Getting mental health support can be daunting, especially when you are
          just not feeling like yourself. The mental health landscape is confusing
          and it’s hard to find the right care provider to suit your needs. There
          are so many different therapists out there and their services can be costly
          and intimidating. Inkblot takes away the barriers to accessing the right
          support and matches you with qualified care providers based on your needs
          and preferences.
        </p>
        <p>
          All activity, including confidential video/voice sessions, booking and
          payment, happens on our platform for convenience and maximum security. We
          have created the most effective way for you to get the help you need and
          deserve from the comfort and safety of your home.
        </p>
        <p>
          Video sessions are available within 12 hours, and soon you’ll be able to
          schedule a same-day booking. Flexible hours for our therapists means
          flexible hours for you, from morning to night, and even on weekends.
        </p>
      </div>
    ),
  },
  {
    id: 2,
    question: 'What is therapy? Is it for me?',
    answer: (
      <div>
        <p>
          Everyone can benefit from therapy. Talking to a professional is the ideal
          way to work through issues and prevent them from becoming more serious. A
          qualified mental health care provider helps you remove, change, or more
          skillfully cope with emotional issues, challenging behaviours, or
          stressful life situations.
        </p>
        <p>Therapy can help you:</p>
        <p>
          • Adjust to a crisis or other current difficulty
          <br />
          • Identify negative beliefs and behaviours and replace them with healthy,
          positive ones
          <br />
          • Explore relationships and experiences, and develop positive interactions
          with others
          <br />
          • Find better ways to cope with and solve problems
          <br />
          • Identify issues that contribute to your mental health and change
          behaviours that make it worse
          <br />
          • Regain a sense of satisfaction and control in your life and help ease
          symptoms, such as hopelessness, anger and excessive worrying
          <br />
          • Learn to set realistic goals for your life
          <br />
          • Develop the ability to tolerate and accept distress using healthier
          behaviours
          <br />
        </p>
        <p>
          Mental health issues negatively affect our emotions, thoughts and behaviour.
          Signs and symptoms vary depending on the person and the disorder, however,
          here are some things to look out for:
        </p>
        <p>
          • Feeling sad or down for an extended period of time
          <br />
          • Confused or unable to concentrate
          <br />
          • Excessive fear, worrying or feelings of guilt
          <br />
          • Extreme mood changes from high to low
          <br />
          • Withdrawal from family, friends and activities
          <br />
          • Significant tiredness, low energy or sleep problems
          <br />
          • Inability to cope with daily problems or stress
          <br />
          • Major changes in eating habits or sex drive
          <br />
          • Excessive anger or irritability
          <br />
          • Paranoia, detachment from reality or hallucinations
          <br />
          • Alcohol or substance abuse
          <br />
          • Suicidal thinking
          <br />
        </p>
        <p>
          <b>
            If you are in crisis with thoughts of harming yourself or others,
            contact emergency services by calling 911 or going to the nearest
            emergency room.
          </b>
        </p>
      </div>
    ),
  },
  {
    id: 3,
    question: 'Does Inkblot provide couples counseling?',
    answer: (
      <div>
        <p>
          Yes. We have several care providers who are certified in couples/marriage
          counseling and improving relationships. Please note that couples (and
          family) counseling cost 50% more. Our support team can apply the price
          change to your account to enable it for couples and family.
        </p>
      </div>
    ),
  },
  {
    id: 4,
    question: 'Does Inkblot provide life coaching?',
    answer: (
      <div>
        <p>
          Yes. Our skilled life coaches help you identify goals (and limitations)
          to work toward outcomes that will bring you success and fulfillment in your
          life. Coaching is usually short-term and includes a focus on professional
          and/or personal development and clear action plans are made to achieve
          desired results.
        </p>
      </div>
    ),
  },
  {
    id: 5,
    question: 'Who are Inkblot’s care providers and what are their qualifications?',
    answer: (
      <div>
        <p>
          Inkblot care providers undergo a stringent vetting process before becoming
          part of our network. The details can vary but, generally, our providers are
          licensed Master-level Counselors, Social Workers, Therapists and
          Psychologists who are in good standing with their state board. They are
          experienced in various clinical settings and must obtain specific designations
          to practice.
        </p>
        {COUNTRY === 'US' && (
          <p>
            We have a range of providers who are in network with most health
            insurance plans as well as those who see clients who pay out of pocket.
          </p>
        )}
        <p>
          *Note: only Registered Psychologists and Psychiatrists are able to diagnose
          mental health conditions. Unlike Psychologists, Psychiatrists are specialized
          medical doctors that can prescribe medication.
        </p>
      </div>
    ),
  },
  {
    id: 6,
    question: 'How do you match me with the right care provider?',
    answer: (
      <div>
        <p>
          Before booking your first session, you’ll be prompted to complete our
          matching questionnaire. This can take as little as a few minutes, depending
          on the level of detail you choose to provide. We then use a unique matching
          algorithm that combines your personal details and preferences with the details
          and strengths of each Inkblot care provider to generate a short list of
          potential providers for you to choose from.
        </p>
        <p>
          The first half hour session is free to ensure that you and your chosen provider
          can work together. In the unlikely event that you are not satisfied with your
          care provider, you will be able to choose another provider from the list and
          that initial session will also be free.
        </p>
        <p>
          If you have a particular care provider you would like to work with, you can
          enter their Referral Code to match with them directly.
        </p>
      </div>
    ),
  },
  {
    id: 7,
    question:
      'What can I expect from therapy and how will I know that it is working?',
    answer: (
      <div>
        <p>
          At the beginning of a session, a therapist typically invites you to share
          what’s been going on in your life, what’s on your mind, what’s bothering you,
          or what you’d like to discuss. Our care providers are compassionate and will
          listen encouragingly and without judgement. This is your opportunity to be open
          and honest and say exactly what you think and feel. The conversation will be
          strictly confidential. Every counsellor and every client is different, however,
          sessions are generally used to gain awareness and insight to solve problems that
          get in the way of you living your best life.
        </p>
        <p>
          Therapy is client-centred and really about whatever a client needs - a one-time
          conversation, a temporary source of support during a life transition, or an ongoing
          experience to optimize health mentally, emotionally, physically and spiritually.
        </p>
        <p>
          Positive changes in your attitude and lifestyle will tell you that therapy is
          working. Your symptoms will decrease and you will start accomplishing your
          goals. It’s important to note that at times you will uncover feelings that make
          you feel worse, however, this is part of the process and will lead to greater
          self-awareness and progress.
        </p>
      </div>
    ),
  },
  {
    id: 8,
    question: 'How does virtual therapy compare to in-person therapy?',
    answer: (
      <div>
        <p>
          Over the last several years, multiple large trials have proven that virtual
          therapy is as effective and, at times, more effective than traditional, in-person
          therapy. However, therapy is a personal experience, and one treatment can’t fit
          everyone’s needs. Some people may find a screen distracting and in-person
          communication more comforting, while others may not even notice, or find it
          easier to express themselves with some separation from the therapist. The comfort
          and convenience of connecting with a therapist from your own home are also unique
          to virtual therapy.
        </p>
        <p>
          There are also important non-therapeutic benefits to our service such as lower
          price and the ability to connect with specialized mental health providers who
          might not have been physically available otherwise.
        </p>
        <p>
          The initial free 30-minute session is an opportunity to familiarize yourself with
          our platform before committing to paid sessions.
        </p>
      </div>
    ),
  },
  {
    id: 9,
    question: 'How is my personal information and privacy protected?',
    answer: (
      <div>
        {COUNTRY === 'US' ? (
          <p>
            We are firmly committed to security and, from day one, have built
            Inkblot to be the most protected HIPAA-compliant platform possible today.
          </p>
        ) : (
          <p>
            We are firmly committed to security and, from day one, have built
            Inkblot to be the most protected platform possible today.
          </p>
        )}
        <p>
          Video/voice sessions on Inkblot are not recorded or stored on any server.
          In fact, they do not go through our servers at all. A military-grade encrypted
          link for your session is made directly between you and your care provider,
          which bypasses our servers.
        </p>
        <p>
          Likewise, we do not store your financial information on our server. A highly
          secure platform called
          {' '}
          <a href="https://stripe.com" target="_blank" rel="noopener noreferrer" >Stripe</a>
          {' '}
          (which is similar to PayPal) handles all billing and financial transactions.
          This is the practice of most online payment systems.
        </p>
        <p>
          Personal information, such as your name, address, and any of your therapist’s
          notes and documents, are stored on a dedicated server with its own protected
          network located in either Canada or the USA (depending on your location). This
          data is encrypted at the highest possible level, and the server is audited
          regularly to ensure it meets the highest standards of best security
          practices (SOC 2 certified).
        </p>
        <p>
          The Inkblot team does not have access to your sessions. We only see information
          needed for administering the site. Even this basic level of information is only
          accessible to a few employees, and their access is regularly audited to ensure
          security and appropriateness. In our <Link to="/privacy">privacy policy</Link>,
          we explicitly make this clear, and it legally binds us not to allow any third
          party company (including your employer or institution) to have access to your
          information.
        </p>
      </div>
    ),
  },
  {
    id: 10,
    question: 'How much do Inkblot sessions cost?',
    answer: (
      <div>
        <p>
          Your initial half hour session on Inkblot is free to ensure that you and
          your care provider are compatible. Subsequent sessions on Inkblot cost
          {COUNTRY === 'US' ? ' $45US ' : ' $37.50CA '}
          per half hour. Sessions can last 30, 60 or 90 minutes.
        </p>
      </div>
    ),
  },
  {
    id: 11,
    question: 'Are Inkblot sessions covered by private health insurance?',
    answer: (
      <div>
        <p>
          Most employee benefits, private insurance, and student health plans cover
          counseling and psychotherapy and, hence, cover Inkblot sessions. Each insurance
          policy is different, however, and we advise clients to check the specific details
          to ensure coverage.
        </p>
      </div>
    ),
  },
  {
    id: 12,
    question: 'What other software and hardware to do I need for sessions with my care provider?',
    answer: (
      <div>
        <p>
          No external software is required in order to take part in a session. It is necessary
          to have a microphone as well as a camera to allow your care provider to both see and
          hear you.
        </p>
      </div>
    ),
  },
  {
    id: 13,
    question: 'Additional questions?',
    answer: (
      <div>
        <p>
          If you have any more questions please contact
          {' '}
          <a href="mailto:support@inkblottherapy.com">
            support@inkblottherapy.com
          </a>
          .
        </p>
        <p>
          <b>
            Tech support is always available using Intercom messaging on our site - the green
            comment icon on the bottom right hand corner of the screen.
          </b>
        </p>
      </div>
    ),
  },
];
