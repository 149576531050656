import React from 'react';
import check from '../../images/_check.svg';
import nope from '../../images/_no.svg';
import inkblotLogo from '../../images/_logomark.svg';

const CompareTableMobile = () => (
  <div className="ink-compare-table-mobile">
    <div className="title-tabs">
      <h1 className="title-left">
        <img style={{ marginBottom: '0' }} src={inkblotLogo} alt="Logo" />
      </h1>
      <h1 className="title-right">Average Canadian In-Person Counselling</h1>
    </div>
    <h1 className="title">Price</h1>
    <div className="compare-container">
      <h2 className="left-compare">$75</h2>
      <h2 className="right-compare">$150-200</h2>
    </div>
    <h1 className="title">Confidential</h1>
    <div className="compare-container">
      <h2 className="left-compare">
        <img src={check} alt="check" />
      </h2>
      <h2 className="right-compare">
        <img src={check} alt="check" />
      </h2>
    </div>
    <h1 className="title">Secure</h1>
    <div className="compare-container">
      <h2 className="left-compare">
        <img src={check} alt="check" />
      </h2>
      <h2 className="right-compare">
        <img src={check} alt="check" />
      </h2>
    </div>
    <h1 className="title">Wait-Times</h1>
    <div className="compare-container">
      <h2 className="left-compare">Less than two weeks</h2>
      <h2 className="right-compare">4-6 weeks</h2>
    </div>
    <h1 className="title">Evenings/Weekends</h1>
    <div className="compare-container">
      <h2 className="left-compare">
        <img src={check} alt="check" />
      </h2>
      <h2 className="right-compare">
        <img src={nope} alt="no" />
      </h2>
    </div>
    <h1 className="title">Location</h1>
    <div className="compare-container">
      <h2 className="left-compare">Anywhere</h2>
      <h2 className="right-compare">Fixed/Office</h2>
    </div>
    <h1 className="title">Focused Specialization</h1>
    <div className="compare-container">
      <h2 className="left-compare">
        <img src={check} alt="check" />
      </h2>
      <h2 className="right-compare">
        <img src={nope} alt="no" />
      </h2>
    </div>
    <h1 className="title">Multiple Languages</h1>
    <div className="compare-container">
      <h2 className="left-compare">
        <img src={check} alt="check" />
      </h2>
      <h2 className="right-compare">
        <img src={nope} alt="no" />
      </h2>
    </div>
    <h1 className="title">Quality Guarantee</h1>
    <div className="compare-container">
      <h2 className="left-compare">
        <img src={check} alt="check" />
      </h2>
      <h2 className="right-compare">
        <img src={nope} alt="no" />
      </h2>
    </div>
    <h1 className="title">First Session Free</h1>
    <div className="compare-container">
      <h2 className="left-compare">
        <img src={check} alt="check" />
      </h2>
      <h2 className="right-compare">
        <img src={nope} alt="no" />
      </h2>
    </div>
    <h1 className="title">Scheduling</h1>
    <div className="compare-container">
      <h2 className="left-compare">Automated</h2>
      <h2 className="right-compare">Manual</h2>
    </div>
    <h1 className="title">Group Sessions</h1>
    <div className="compare-container">
      <h2 className="left-compare">$20/hour</h2>
      <h2 className="right-compare">
        <img src={nope} alt="no" />
      </h2>
    </div>
    <h1 className="title">Cancellation Policy</h1>
    <div className="compare-container">
      <h2 className="left-compare">8 hours/half price</h2>
      <h2 className="right-compare">24 hours/full price</h2>
    </div>
    <h1 className="title">Match Type</h1>
    <div className="compare-container">
      <h2 className="left-compare">Computer based</h2>
      <h2 className="right-compare">Random</h2>
    </div>
    <h1 className="title">Match Type</h1>
    <div className="compare-container">
      <h2 className="left-compare">24 hours/day</h2>
      <h2 className="right-compare">
        <img src={nope} alt="no" />
      </h2>
    </div>
  </div>
);

export default CompareTableMobile;
