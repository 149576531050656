/* eslint-disable consistent-return */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import PropTypes from 'prop-types';
import axios from 'axios';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';

import {
  SERVER_URL,
  THERAPY_URL,
  COUNTRY,
  OTHER_MARKETING_URL,
  ENVIRONMENT,
} from '../../utils/environment';
import {
  unauthAuditProgress,
  auditProgress,
  parseEapCodeFromPath,
} from '../../utils/pageUtils';
import LogEventOnMount from '../LogEventOnMount';
import { OPEN_SIGNUP, logEvent, COMPLETED_SIGNUP } from '../../utils/amplitude';

class DMZSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailError: false,
    };
  }

  componentWillMount() {
    const eap = window.location.search.split('eap=')[1];
    const americanOnly = ['cbn'].includes(eap);
    if (americanOnly && COUNTRY === 'CA' && ENVIRONMENT !== 'DEVELOPMENT') {
      window.location.replace(`${OTHER_MARKETING_URL}/sign_up?eap=${eap}`);
    }
  }

  componentDidMount() {
    unauthAuditProgress(parseEapCodeFromPath(), 'open_signup');
  }

  handleChange = event => {
    const { name, value } = event.target;
    if (name === 'email') {
      this.emailExists(value);
    }
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.signUp();
  };

  emailExists = email => {
    axios
      .get(`${SERVER_URL}/api/signup/check_email?email=${email}`)
      .then(() => {
        this.setState({ emailError: false });
      })
      .catch(() => {
        this.setState({
          emailError: true,
        });
      });
  };

  signUp = () => {
    if (this.state.emailError) {
      return false;
    }
    const corporate_account =
      window.location.search.split('eap=')[1] === 'eastcoaststrong'
        ? null
        : window.location.search.split('eap=')[1];
    const referral_source =
      window.location.search.split('eap=')[1] === 'eastcoaststrong' &&
      !this.state.referral_source
        ? 'East Coast Strong'
        : this.state.referral_source;
    axios
      .post(`${SERVER_URL}/api/signup`, {
        ...this.state,
        corporate_account,
        referral_source,
      })
      .then(response => {
        const token = response.data.access_token;
        const inkblotUrl = `${THERAPY_URL}/redirect/${token}`;
        logEvent(COMPLETED_SIGNUP, { page: parseEapCodeFromPath() });
        auditProgress(parseEapCodeFromPath(), 'completed_signup', token);
        window.location.replace(inkblotUrl);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  render() {
    const showRole = ['mapleleaffoods', 'thesweetpotato', 'cbn'].includes(window.location.search.split('eap=')[1]);
    const showCompany = ['cbn'].includes(window.location.search.split('eap=')[1]);
    const showCountry = ['mapleleaffoods'].includes(window.location.search.split('eap=')[1]);
    const showFullWidth = [
      'dmz',
      'mapleleaffoods',
      'cbn',
      'eastcoaststrong',
      'dream',
    ].includes(window.location.search.split('eap=')[1]);
    const showSchool = ['eastcoaststrong'].includes(window.location.search.split('eap=')[1]);
    return (
      <div className="custom-auth custom-dependants">
        <MetaTags>
          <meta name="robots" content="noindex" />
        </MetaTags>

        <div
          className="dependants-container custom-auth-container"
          style={{ margin: '50px auto' }}
        >
          <div className="dependant-auth-form">
            <h2>
              {this.props.intl.formatMessage({
                id: 'eap.signup',
                defaultMessage: 'Sign up',
              })}
            </h2>
            <form onSubmit={this.handleSubmit}>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.firstName',
                    defaultMessage: 'First Name',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="first_name"
                  placeholder="John"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.lastName',
                    defaultMessage: 'Last Name',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="last_name"
                  placeholder="Doe"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.email',
                    defaultMessage: 'Email',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="email"
                  placeholder="jdoe@example.com"
                  className={showFullWidth && 'full-width'}
                />
                {this.state.emailError && (
                  <p className="error-text">
                    Email Already Exists. Please Sign In Below.
                  </p>
                )}
              </div>
              {showSchool && (
                <div>
                  <h4>
                    {this.props.intl.formatMessage({
                      id: 'eap.school',
                      defaultMessage: 'Institution/School (Optional)',
                    })}
                  </h4>
                  <input
                    onChange={value => this.handleChange(value)}
                    type="text"
                    name="referral_source"
                    className="full-width"
                  />
                </div>
              )}
              {showRole && (
                <div>
                  <h4>
                    {this.props.intl.formatMessage({
                      id: 'eap.role',
                      defaultMessage: 'Status',
                    })}
                  </h4>
                  <select
                    className="role-dropdown"
                    onChange={value => this.handleChange(value)}
                    type="text"
                    name="status"
                    defaultValue=""
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="1">Employee/Member</option>
                    <option value="3">Spouse</option>
                    <option value="4">Dependent</option>
                  </select>
                </div>
              )}
              {showCountry && (
                <div>
                  <h4>
                    {this.props.intl.formatMessage({
                      id: 'eap.country',
                      defaultMessage: 'Country',
                    })}
                  </h4>
                  <select
                    className="role-dropdown"
                    onChange={value => this.handleChange(value)}
                    type="text"
                    name="country"
                    defaultValue=""
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="Canada">Canada</option>
                    <option value="United States">United States</option>
                  </select>
                </div>
              )}
              {showCompany && (
                <div>
                  <h4>
                    {this.props.intl.formatMessage({
                      id: 'eap.company',
                      defaultMessage: 'Company',
                    })}
                  </h4>
                  <select
                    className="role-dropdown"
                    onChange={value => this.handleChange(value)}
                    type="text"
                    name="company_alias"
                    defaultValue=""
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="CBNSTI">
                      CBN Secure Technologies Inc. (CBNSTI)
                    </option>
                    <option value="NFCBN">Nanofactory CBN Inc. (NFCBN)</option>
                    <option value="CBNSTIL">CBNSTI-Lottery</option>
                  </select>
                </div>
              )}
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.password',
                    defaultMessage: 'Password',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="password"
                  name="password"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.confirmPassword',
                    defaultMessage: 'Retype Password',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="password"
                  name="password"
                />
              </div>
              <div>
                <button type="submit">
                  {this.props.intl.formatMessage({
                    id: 'eap.signup',
                    defaultMessage: 'Sign Up',
                  })}
                </button>
              </div>
            </form>
            <h4>
              {this.props.intl.formatMessage({
                id: 'eap.alreadyHave',
                defaultMessage: 'Already have an account?',
              })}
              <Link to="/sign_in?eap=dmz" style={{ color: '#2a5ccd' }}>
                {this.props.intl.formatMessage({
                  id: 'eap.signin',
                  defaultMessage: 'Sign In',
                })}
              </Link>
            </h4>
          </div>
        </div>
        <LogEventOnMount
          eventType={OPEN_SIGNUP}
          eventProperties={{ page: parseEapCodeFromPath() }}
        />
      </div>
    );
  }
}

DMZSignup.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(DMZSignup);
