/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import MetaTags from 'react-meta-tags';

import SessionTwo from '../../images/custom/illustrations/session-2.svg';
import { SERVER_URL, THERAPY_URL } from '../../utils/environment';
import {
  unauthAuditProgress,
  whichCompany,
  auditProgress,
} from '../../utils/pageUtils';
import LogEventOnMount from '../LogEventOnMount';
import {
  OPEN_SIGNUP,
  OPEN_FORGOT_PASSWORD,
  logEvent,
  COMPLETED_SIGNUP,
} from '../../utils/amplitude';
import { atbSignupData } from '../../pagePartials/auth';

class ATBSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: false,
      showForm: false,
      error: {},
    };
  }

  componentDidMount() {
    unauthAuditProgress(whichCompany().path, 'open_forgot_password');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recentlyUpdated !== prevState.recentlyUpdated) {
      this.validateForm();
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      message: false,
      recentlyUpdated: name,
    });
  };

  forgotPassword = () => {
    const { employee_id } = this.state;
    axios
      .post(`${SERVER_URL}/api/v2/forgot_passwords/atb_signup`, { employee_id })
      .then(response => {
        if (response.data.message && response.data.message === 'welcome_email_sent') {
          logEvent(OPEN_SIGNUP, { page: whichCompany().path });
          unauthAuditProgress(whichCompany().path, 'sent_forgot_password');
          this.setState({ message: 'We will send you an email to create your account shortly.' });
        } else {
          this.setState({ message: response.data.message });
        }
      })
      .catch(() => {
        this.setState({ message: 'Not a valid Employee ID.' });
      });
  };

  signup = () => {
    const values = _.omit(this.state, [
      'showForm',
      'message',
      'signUpDisabled',
      'error',
      'signUpError',
      'recentlyUpdated',
    ]);
    const corporate_account = whichCompany().path;
    axios
      .post(`${SERVER_URL}/api/v2/forgot_passwords/atb_employee_leave`, values)
      .then(response => {
        const token = response.data.access_token;
        logEvent(COMPLETED_SIGNUP, { page: corporate_account });
        auditProgress(corporate_account, 'completed_signup', token);
        window.location.replace(`${THERAPY_URL}/redirect/${token}`);
      })
      .catch(error => {
        if (error.response && error.response.data.error) {
          this.setState({ message: error.response.data.error });
        } else {
          this.setState({ message: 'Error!' });
        }
      });
  };

  handleSubmitForCheck = event => {
    event.preventDefault();
    this.forgotPassword();
  };

  handleSignupForCheck = event => {
    event.preventDefault();
    this.signup();
  };

  emailExists = email => {
    axios
      .get(`${SERVER_URL}/api/signup/check_email?email=${encodeURIComponent(email)}`)
      .then(() => {})
      .catch(e => {
        this.setState({
          error: {
            ...this.state.error,
            email: e.response.data.error,
          },
        });
      });
  };

  validateForm = () => {
    const emailValid = 'Email must be valid';
    const employeeIdValid = 'Employee ID must follow e## or E### format';
    const passwordSix = 'Password must be at least 6 characters';
    const passwordMatch = 'Passwords must match';

    const {
      first_name,
      last_name,
      email,
      password,
      password_confirm,
      employee_id,
    } = this.state;
    const error = {};

    if (email) {
      const validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!validEmail.test(email)) {
        error.email = emailValid;
      }
      this.emailExists(email);
    }
    if (password) {
      if (password.length < 6) {
        error.password = passwordSix;
      } else if (password !== password_confirm) {
        error.password_confirm = passwordMatch;
      }
    }
    if (employee_id) {
      const validEmployeeId = /^(e|E)[0-9]+$/;
      if (!validEmployeeId.test(employee_id)) {
        error.employee_id = employeeIdValid;
      }
    }

    this.setState({
      recentlyUpdated: null,
      signUpDisabled:
        !_.isEmpty(error) ||
        !first_name ||
        !last_name ||
        !email ||
        !password ||
        !password_confirm ||
        !employee_id,
      error,
    });
  };

  renderInputs = inputs => {
    const { error } = this.state;
    return (
      _.map(inputs, ({
        id, name, type, fullWidth, text, defaultText,
      }) => (
        <div key={id}>
          <h4>
            {this.props.intl.formatMessage({
                  id: text,
                  defaultMessage: defaultText,
                })}
          </h4>
          <input
            id={name}
            onChange={value => this.handleChange(value)}
            type={type}
            name={name}
            className={fullWidth ? 'full-width' : ''}
            required
          />
          <p className={`error-text ${error[name] ? '' : 'hidden'}`} style={{ fontSize: '11px', position: 'absolute' }}>
            {error[name]}
          </p>
        </div>
      ))
    );
  };

  renderSignupForm = () => (
    <div className="dependants-container custom-auth-container">
      <div className="dependant-auth-form">
        <h2>
          {this.props.intl.formatMessage({
            id: 'eap.accountCreation',
            defaultMessage: 'Account Creation',
          })}
        </h2>
        <form onSubmit={this.handleSignupForCheck}>
          {this.renderInputs(atbSignupData)}
          <div>
            <button type="submit" disabled={this.state.signUpDisabled}>
              {this.props.intl.formatMessage({
                id: 'eap.signup',
                defaultMessage: 'Sign Up',
              })}
            </button>
          </div>
        </form>
        <span
          role="button"
          tabIndex={0}
          onClick={() => this.setState({ showForm: false })}
          style={{
            color: 'rgb(42, 92, 205)', cursor: 'pointer', fontSize: '18px', fontFamily: 'Barlow', fontWeight: 'bold',
          }}
        >
          Back
        </span>
      </div>
    </div>
  );

  render() {
    const { message, showForm } = this.state;
    const { path } = whichCompany();
    return (
      <div className="custom-auth">
        <MetaTags>
          <meta name="robots" content="noindex" />
        </MetaTags>
        {
            showForm ? this.renderSignupForm() :
            <div className="auth-container custom-auth-container">
              <div className="custom-auth-text">
                <h2>
                  {this.props.intl.formatMessage({
                  id: 'eap.gettingStarted',
                  defaultMessage: 'Getting Started',
                })}
                </h2>
                <div>
                  <p>
                    {this.props.intl.formatMessage({
                    id: 'eap.mostLikelyReceived',
                    defaultMessage:
                      'You most likely have received an email from Inkblot sent to your work email address with a direct link to set up your account.',
                  })}
                  </p>
                  <p>
                    {this.props.intl.formatMessage({
                    id: 'eap.atbNotFindEmail',
                    defaultMessage:
                      'If you cannot find the registration email, or if you are a new employee who has not been registered yet, please enter your employee ID below:',
                  })}
                  </p>
                </div>
                <form onSubmit={this.handleSubmitForCheck}>
                  <h4>
                    {this.props.intl.formatMessage({
                    id: 'eap.employeeIdStarred',
                    defaultMessage: 'Employee ID *',
                  })}
                  </h4>
                  <input name="employee_id" type="text" onChange={this.handleChange} />
                  <button disabled={message} type="submit">
                    {this.props.intl.formatMessage({
                    id: 'eap.submit',
                    defaultMessage: 'Submit',
                  })}
                  </button>
                </form>
                {message && (
                <p>
                  {message ||
                    'We will send you an email to create your account shortly.'}
                </p>
              )}
                <h4>
                  {this.props.intl.formatMessage({
                  id: 'eap.spouseOrDependant',
                  defaultMessage: 'Are you a spouse or a dependant?',
                })}{' '}
                  <Link
                    to={`/dependants?eap=${path}`}
                    style={{ color: '#2a5ccd' }}
                  >
                    {this.props.intl.formatMessage({
                    id: 'eap.registerHere',
                    defaultMessage: 'Sign Up Here',
                  })}
                  </Link>
                </h4>
                <h4>
                  {this.props.intl.formatMessage({
                  id: 'eap.memberOnLeave',
                  defaultMessage: 'Are you a team member on leave?',
                })}{' '}
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={() => this.setState({ showForm: true })}
                    style={{ color: '#2a5ccd', cursor: 'pointer' }}
                  >
                    {this.props.intl.formatMessage({
                    id: 'eap.registerHere',
                    defaultMessage: 'Sign Up Here',
                  })}
                  </span>
                </h4>
                <h4>
                  {this.props.intl.formatMessage({
                  id: 'eap.alreadyHave',
                  defaultMessage: 'Already have an account?',
                })}
                  <Link to={`/sign_in?eap=${path}`} style={{ color: '#2a5ccd' }}>
                    {this.props.intl.formatMessage({
                    id: 'eap.signin',
                    defaultMessage: 'Sign In',
                  })}
                  </Link>
                </h4>
              </div>
              <img src={SessionTwo} alt="session two" />
            </div>
          }
        <LogEventOnMount
          eventType={OPEN_FORGOT_PASSWORD}
          eventProperties={{ page: path }}
        />
      </div>
    );
  }
}

ATBSignup.propTypes = {
  intl: PropTypes.object,
};

ATBSignup.defaultProps = {
  intl: {},
};

export default injectIntl(ATBSignup);
