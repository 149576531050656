import React, { Component } from 'react';
import LanguageSelector from './LanguageSelector';
import { FormattedHTMLMessage } from 'react-intl';

import CSALogo from '../../images/people-connect-total-health/csa.png';
import CSAFrLogo from '../../images/people-connect-total-health/csa-fr.jpg';

import InkblotLogo from '../../images/people-connect-total-health/inkblot.png';
import BeaconLogo from '../../images/people-connect-total-health/beacon.png';
import BeaconLogoTwo from '../../images/people-connect-total-health/beacon-two.png';
import LifeSpeakLogo from '../../images/people-connect-total-health/life-speak.png';
import WorkplaceOptions from '../../images/people-connect-total-health/work-place-options.png';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { table: false };
  }
  toggleTable = () => {
    this.setState(prevState => ({ table: !prevState.table }));
  };

  render() {
    const lang = localStorage.getItem('selectedLanguage');
    return (
      <div className="csa">
        <LanguageSelector />
        <div className="section-1">
          {lang === 'en' ? (
            <img className="people-logo" src={CSALogo} alt="people Logo" />
          ) : (
            <img className="people-logo" src={CSAFrLogo} alt="people Logo" />
          )}
          <FormattedHTMLMessage
            tagName="h2"
            id="csa.yourMental"
            defaultMessage="Your mental health resources"
          />
          <div className="divider" />
          <FormattedHTMLMessage
            tagName="p"
            id="csa.learnMore"
            defaultMessage="Learn more about your comprehensive benefits package."
          />
          <FormattedHTMLMessage tagName="h4" id="pch.yourProviders" />
          <div className="company-container">
            <div>
              <img src={InkblotLogo} alt="inkblot" />
            </div>
            <div>
              <img src={BeaconLogo} alt="beacon" />
            </div>
            <div>
              <img src={LifeSpeakLogo} alt="lifespeak" />
            </div>
            <div>
              <img src={WorkplaceOptions} alt="workplace options" />
            </div>
          </div>
        </div>
        <div className="section-2">
          <div className="container">
            <div className="left">
              <div className="white-box">
                <img src={InkblotLogo} alt="" />
              </div>
            </div>
            <div className="right">
              <FormattedHTMLMessage
                tagName="h2"
                id="csa.talkTherapy"
                defaultMessage="Talk Therapy"
              />
              <FormattedHTMLMessage
                tagName="h1"
                id="csa.inkblot"
                defaultMessage="Inkblot Therapy"
              />
              <FormattedHTMLMessage
                tagName="p"
                id="csa.inkblotTop"
                defaultMessage="Inkblot Therapy is Canada’s top provider of online video-based one-on-one or couples counselling.  Care is provided by highly experienced professionals across Canada.  Inkblot highly values long-term relationships with care providers.  Your first 30-minute individual counselling session is complimentary.  Subsequent sessions are reimbursable through your paramedical health coverage or Health Spending Account (HSA).<br><br><b>Most suitable for those seeking online counselling and a longer-term relationship with their counsellor.</b>"
              />
              <a
                className="pch-button"
                href="https://inkblottherapy.com/CSA"
                target="_blank"
                rel="noreferrer"
              >
                <FormattedHTMLMessage tagName="a" id="csa.launchInkblot" />
              </a>
            </div>
          </div>
        </div>
        <div className="section-3">
          <div className="container">
            <div className="left">
              <div className="white-box">
                <img
                  src={BeaconLogoTwo}
                  style={{
                    objectFit: 'contain',
                    width: '300px',
                    height: '150px',
                  }}
                  alt="beacon"
                />
              </div>
            </div>
            <div className="right">
              <FormattedHTMLMessage
                tagName="h2"
                id="csa.guidedDigitalTherapy"
                defaultMessage="Virtual Health"
              />
              <FormattedHTMLMessage
                tagName="h1"
                id="csa.beacon"
                defaultMessage="Beacon"
              />
              <FormattedHTMLMessage tagName="p" id="csa.beaconIsAvail" />
              <a
                className="pch-button"
                href={
                  lang === 'en'
                    ? 'https://www.mindbeacon.com/bcsa824'
                    : 'https://www.mindbeacon.com/fr/bcsa824'
                }
                target="_blank"
              >
                <FormattedHTMLMessage
                  tagName="a"
                  id="csa.launchBeacon"
                  defaultMessage="Launch Beacon"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="view-compare">
          <FormattedHTMLMessage tagName="h2" id="csa.viewCompare" />
          <a className="view-button" onClick={this.toggleTable}>
            {this.state.table ? (
              <FormattedHTMLMessage tagName="span" id="csa.hideChart" />
            ) : (
              <FormattedHTMLMessage tagName="span" id="csa.viewChart" />
            )}
          </a>
        </div>
        {this.state.table && (
          <div className="compare-section">
            <div id="compare-table" className="compare-table">
              <div className="header">
                <p className="left-text"></p>
                <div className="block">
                  <h2>Inkblot</h2>
                </div>
                <div className="block">
                  <h2>Beacon</h2>
                </div>
              </div>
              <div className="table-block">
                <p className="left-text">
                  <FormattedHTMLMessage tagName="p" id="csa.offers" />
                </p>
                <div className="block">
                  <FormattedHTMLMessage tagName="p" id="csa.accessibleVideo" />
                </div>
                <div className="block">
                  <FormattedHTMLMessage tagName="p" id="csa.twelveWeek" />
                </div>
              </div>
              <div className="table-block">
                <p className="left-text">
                  <FormattedHTMLMessage tagName="p" id="csa.idealFor" />
                </p>
                <div className="block">
                  <FormattedHTMLMessage tagName="p" id="csa.anyPersonal" />
                </div>
                <div className="block">
                  <FormattedHTMLMessage
                    tagName="p"
                    id="csa.depressionAnxiety"
                  />
                </div>
              </div>
              <div className="table-block">
                <p className="left-text">
                  <FormattedHTMLMessage tagName="p" id="csa.coverage" />
                </p>
                <div className="block">
                  <FormattedHTMLMessage
                    tagName="p"
                    id="csa.appointmentsAreBooked"
                  />
                </div>
                <div className="block">
                  <FormattedHTMLMessage tagName="p" id="csa.csaWillCover" />
                </div>
              </div>
              <div className="table-block">
                <p className="left-text">
                  <FormattedHTMLMessage tagName="p" id="csa.forMoreInfo" />
                </p>
                <div className="block">
                  <a href="https://inkblottherapy.com/csa" target="_blank">
                    <b>www.inkblottherapy.com/csa</b>
                  </a>
                </div>
                <div className="block">
                  <a href="https://www.mindbeacon.com/bcsa824" target="_blank">
                    {lang === 'en' ? 'English' : 'Anglais'}:{' '}
                    <b>www.mindbeacon.com/bcsa824</b>
                    <br />
                  </a>
                  <br />
                  <br />
                  <a
                    href="https://www.mindbeacon.com/fr/bcsa824"
                    target="_blank"
                  >
                    {lang === 'en' ? 'French' : 'Français'}:{' '}
                    <b>www.mindbeacon.com/fr/bcsa824</b>
                  </a>
                </div>
              </div>
            </div>
            <div className="compare-table-mobile">
              <div className="block">
                <p className="title">
                  <FormattedHTMLMessage tagName="span" id="csa.offers" />
                </p>
                <div className="table-block-mobile">
                  <h2>Inkblot</h2>
                  <FormattedHTMLMessage tagName="p" id="csa.accessibleVideo" />
                </div>
                <div className="table-block-mobile">
                  <h2>Beacon</h2>
                  <FormattedHTMLMessage tagName="p" id="csa.twelveWeek" />
                </div>
              </div>
              <div className="block">
                <p className="title">
                  <FormattedHTMLMessage tagName="span" id="csa.idealFor" />
                </p>
                <div className="table-block-mobile">
                  <h2>Inkblot</h2>
                  <FormattedHTMLMessage tagName="p" id="csa.anyPersonal" />
                </div>
                <div className="table-block-mobile">
                  <h2>Beacon</h2>
                  <FormattedHTMLMessage
                    tagName="p"
                    id="csa.depressionAnxiety"
                  />
                </div>
              </div>
              <div className="block">
                <p className="title">
                  <FormattedHTMLMessage tagName="span" id="csa.coverage" />
                </p>
                <div className="table-block-mobile">
                  <h2>Inkblot</h2>
                  <FormattedHTMLMessage
                    tagName="p"
                    id="csa.appointmentsAreBooked"
                  />
                </div>
                <div className="table-block-mobile">
                  <h2>Beacon</h2>
                  <FormattedHTMLMessage tagName="p" id="csa.csaWillCover" />
                </div>
              </div>
              <div className="block">
                <p className="title">
                  <FormattedHTMLMessage tagName="span" id="csa.forMoreInfo" />
                </p>
                <div className="table-block-mobile">
                  <h2>Inkblot</h2>
                  <a href="https://inkblottherapy.com/csa" target="_blank">
                    <b>www.inkblottherapy.com/csa</b>
                  </a>
                </div>
                <div className="table-block-mobile">
                  <h2>Beacon</h2>
                  <a href="https://www.mindbeacon.com/bcsa824" target="_blank">
                    {lang === 'en' ? 'English' : 'Anglais'}:{' '}
                    <b>www.mindbeacon.com/bcsa824</b>
                    <br />
                  </a>
                  <br />
                  <br />
                  <a
                    href="https://www.mindbeacon.com/fr/bcsa824"
                    target="_blank"
                  >
                    {lang === 'en' ? 'French' : 'Français'}:{' '}
                    <b>www.mindbeacon.com/fr/bcsa824</b>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="section-4">
          <div className="container">
            <div className="left">
              <div className="white-box">
                <img src={LifeSpeakLogo} alt="lifespeak" />
              </div>
            </div>
            <div className="right">
              <FormattedHTMLMessage tagName="h2" id="csa.wellBeingPlatform" />
              <FormattedHTMLMessage
                tagName="h1"
                id="csa.lifeSpeak"
                defaultMessage="LifeSpeak"
              />
              <FormattedHTMLMessage tagName="p" id="csa.lifeSpeakIs" />
              <a
                className="pch-button"
                href="https://csagroup.lifespeak.com/Share.aspx?key=e81e3fd0-51cf-4025-8172-e6b5e93089a3--language-1"
                target="_blank"
              >
                <FormattedHTMLMessage
                  tagName="a"
                  id="csa.accessOnline"
                  defaultMessage="View Details"
                />
              </a>
              <FormattedHTMLMessage
                tagName="p"
                id="csa.id"
                defaultMessage="id = lifespeak"
              />
              <FormattedHTMLMessage
                tagName="p"
                id="csa.lifeSpeakDownload"
                defaultMessage="Or download the app in the Google Play or App Store using the following credentials:<br>
		  <b>Client Name</b>: csagroup <br>
		  <b>Client Password</b>: lifespeak"
              />
            </div>
          </div>
        </div>
        <div className="section-5">
          <div className="container">
            <div className="left">
              <div className="white-box">
                <img src={WorkplaceOptions} alt="workplace options" />
              </div>
            </div>
            <div className="right">
              <FormattedHTMLMessage tagName="h2" id="csa.workplaceOptions" />
              <FormattedHTMLMessage tagName="p" id="csa.employeeSupportProg" />
              <a
                className="pch-button"
                href="http://global.resourcesforyourlife.com"
                target="_blank"
              >
                <FormattedHTMLMessage tagName="a" id="csa.launchWorkplace" />
              </a>
              <FormattedHTMLMessage tagName="p" id="csa.companyCode" />
            </div>
          </div>
        </div>
        <div className="section-6">
          <div className="container">
            <div className="left">
              <div className="white-box">
                {lang === 'en' ? (
                  <img
                    className="people-logo"
                    src={CSALogo}
                    alt="people Logo"
                  />
                ) : (
                  <img
                    className="people-logo"
                    src={CSAFrLogo}
                    alt="people Logo"
                  />
                )}
              </div>
            </div>
            <div className="right">
              <FormattedHTMLMessage tagName="h2" id="csa.extraCoverage" />
              <FormattedHTMLMessage tagName="h1" id="csa.paramedical" />
              <FormattedHTMLMessage tagName="p" id="csa.paramedicalCoverage" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
