import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import get from 'lodash/get';
import queryString from 'query-string';

import {
  SERVER_URL,
  THERAPY_URL,
  OTHER_SERVER_URL,
  OTHER_THERAPY_URL,
  COUNTRY,
} from '../../utils/environment';

class Dependants extends Component {
  constructor(props) {
    super(props);
    const params = queryString.parse(window.location.search);

    this.state = {
      errorMessage: '',
      eapCode: (params.eap || '').toLowerCase(),
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.signUp();
  };

  signUp = () => {
    const { country, eapCode } = this.state;
    let apiUrl;
    let appUrl;

    if (country === 'Canada' && COUNTRY === 'CA') {
      apiUrl = SERVER_URL;
      appUrl = THERAPY_URL;
    } else {
      apiUrl = OTHER_SERVER_URL;
      appUrl = OTHER_THERAPY_URL;
    }

    axios
      .post(`${apiUrl}/api/v2/eap_dependants`, {
        ...this.state,
        corporate_account: eapCode,
        source: 'therapy',
      })
      .then(response => {
        const token = response.data.access_token;
        const inkblotUrl = `${appUrl}/redirect/${token}`;
        window.location.replace(inkblotUrl);
      })
      .catch(e => {
        this.setState({ errorMessage: get(e, 'response.data.message', '') });
      });
  };

  renderErrorMessage = () => {
    const { errorMessage } = this.state;

    return (
      <div>
        <p className="error-text">{errorMessage}</p>
      </div>
    );
  };

  renderRBDivision = () => (
    <div>
      <h4 className="label">Division</h4>
      <select
        className="full-width referral-dropdown"
        required
        onChange={value => this.handleChange(value)}
        type="text"
        name="rb_division"
        defaultValue=""
      >
        <option value="">
          {this.props.intl.formatMessage({
            id: 'eap.pleaseSelect',
            defaultMessage: 'Please select an option.',
          })}
        </option>
        <option value="rb_nutrition">RB Nutrition</option>
        <option value="rb_hygiene">RB Hygiene</option>
      </select>
    </div>
  );

  render() {
    const { eapCode } = this.state;

    return (
      <div className="custom-auth custom-dependants">
        <div className="dependants-container custom-auth-container">
          <div className="dependant-auth-form">
            <h2>
              {this.props.intl.formatMessage({
                id: 'eap.dependantAccountCreate',
                defaultMessage: 'Dependant Account Creation',
              })}
            </h2>
            <form onSubmit={this.handleSubmit}>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.firstName',
                    defaultMessage: 'First Name',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="first_name"
                  placeholder="John"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.lastName',
                    defaultMessage: 'Last Name',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="last_name"
                  placeholder="Doe"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.email',
                    defaultMessage: 'Email',
                  })}
                </h4>
                <input
                  className="full-width referral-dropdown"
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="email"
                  placeholder="jdoe@example.com"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.password',
                    defaultMessage: 'Password',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="password"
                  name="password"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.confirmPassword',
                    defaultMessage: 'Retype Password',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="password"
                  name="password"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.country',
                    defaultMessage: 'Country',
                  })}
                </h4>
                <select
                  className="full-width referral-dropdown"
                  onChange={value => this.handleChange(value)}
                  type="text"
                  name="country"
                  defaultValue=""
                  required
                >
                  <option value="">Select an option</option>
                  <option value="Canada">Canada</option>
                  <option value="United States">United States</option>
                </select>
              </div>
              <div>
                <h4 className="label">
                  {this.props.intl.formatMessage({
                    id: 'eap.relationship',
                    defaultMessage: 'Relationship',
                  })}
                </h4>
                <select
                  className="full-width referral-dropdown"
                  required
                  onChange={value => this.handleChange(value)}
                  type="text"
                  name="relationship"
                  defaultValue=""
                >
                  <option value="">
                    {this.props.intl.formatMessage({
                      id: 'eap.pleaseSelect',
                      defaultMessage: 'Please select an option.',
                    })}
                  </option>
                  <option value="spouse">
                    {this.props.intl.formatMessage({
                      id: 'eap.spouse',
                      defaultMessage: 'Spouse/Partner',
                    })}
                  </option>
                  <option value="dependant">
                    {this.props.intl.formatMessage({
                      id: 'eap.dependant',
                      defaultMessage: 'Dependant',
                    })}
                  </option>
                </select>
              </div>
              {eapCode === 'rb' && this.renderRBDivision()}
              <div>
                <button type="submit">
                  {this.props.intl.formatMessage({
                    id: 'eap.signup',
                    defaultMessage: 'Sign Up',
                  })}
                </button>
              </div>
            </form>
            {this.renderErrorMessage()}
            <h4>
              {this.props.intl.formatMessage({
                id: 'eap.areYouEmployee',
                defaultMessage: 'Are you an employee?',
              })}{' '}
              <Link to={`/sign_up?eap=${eapCode}`} style={{ color: '#2a5ccd' }}>
                {this.props.intl.formatMessage({
                  id: 'eap.getStarted',
                  defaultMessage: 'Get Started',
                })}
              </Link>
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

Dependants.propTypes = {
  intl: PropTypes.object,
};

Dependants.defaultProps = {
  intl: {},
};

export default injectIntl(Dependants);
