import React from 'react';
import _ from 'lodash';
import SectionContainer from '../../components/SectionContainer';
import { aboutData } from '../../pagePartials/gallivan/about';
import inkblotLogo from '../../images/_ink-logo.svg';
import { bossData } from '../../pagePartials/about';

const About = () => (
  <div className="gallivan-about-page">
    <SectionContainer container num={1}>
      <h1>About</h1>
      {_.map(aboutData, ({ id, text, title }) => (
        <div key={id}>{title ? <h2>{text}</h2> : <p>{text}</p>}</div>
      ))}
      <figure>
        <img src={inkblotLogo} alt="Inkblot" />
      </figure>
    </SectionContainer>
    <SectionContainer container num={2}>
      <div className="bosses-🤑">
        {_.map(bossData, ({ id, name, title, image }) => (
          <div key={id} className="card">
            <div className="row">
              <div className="col">
                <img src={image} className="profile" alt="Portrait" />
              </div>
              <div className="col" style={{ paddingTop: '30px' }}>
                <p className="font-bold">{name}</p>
                <p>Co-Founder</p>
                <p>{title}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </SectionContainer>
  </div>
);

export default About;
