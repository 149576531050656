import React from 'react';
import _ from 'lodash';
import Bitmap1 from '../../images/_Bitmap.png';
import Bitmap2 from '../../images/_Bitmap2.png';
import Bitmap3 from '../../images/_Bitmap3.png';
import Bitmap4 from '../../images/_Bitmap4.png';
import Bitmap5 from '../../images/_Bitmap5.png';
import Bitmap6 from '../../images/_Bitmap6.png';
import Bitmap7 from '../../images/_Bitmap7.png';

const bitmaps = [Bitmap1, Bitmap2, Bitmap3, Bitmap4, Bitmap5, Bitmap6, Bitmap7];

const renderBitMaps = bm => (
  <div className="bitmap-container">
    {_.map(bm, b => (
      <div key={bm.indexOf(b)} className="image-container">
        <img key={bm.indexOf(b)} src={b} alt={b.toString()} />
      </div>
    ))}
  </div>
);

const AsSeenOn = () => (
  <div>
    <h2 style={{ marginBottom: '50px' }}>As Seen On</h2>
    {renderBitMaps(bitmaps)}
  </div>
);

AsSeenOn.propTypes = {};

export default AsSeenOn;
